import { FaceServicePromiseClient } from '@lctech-tw/jkface-proto/dist/js/face-front-api/v2/face_grpc_web_pb';
import { ConvertFaceIdReq, } from '@lctech-tw/jkface-proto/dist/js/face-front-api/v2/face_pb';
import { enableGrpcWebDevtools } from '@/api/grpc-tool/enable-grpc-web-devtools';
export class FaceService {
    constructor() {
        this.getObjectFromUid = async (params) => {
            const req = new ConvertFaceIdReq();
            req.setUidList([params.faceUid]);
            const res = await this.faceClient.convertFaceId(req);
            const firstFaceIndex = 0;
            const faceObjectIdIndex = 1;
            return res.toObject().uidToObjectIdMap[firstFaceIndex][faceObjectIdIndex];
        };
        this.faceClient = new FaceServicePromiseClient(process.env.VUE_APP_FACE_SERVICE_HOST);
        enableGrpcWebDevtools([
            this.faceClient,
        ]);
    }
}
