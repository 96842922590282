import dog from '@lctech-tw/f2e-doooooooog';
// JkfPay.Activity
dog.config.setHost('ACTIVITY', process.env.VUE_APP_DONATE_API_HOST);
export const GetInfoByID = dog.activity.api.GetInfoByID;
export const GetMyFreeItem = dog.activity.api.GetMyFreeItem;
export const GetMyPaidItem = dog.activity.api.GetMyPaidItem;
export const GetPaidItemInfo = dog.activity.api.GetPaidItemInfo;
export const GetLadyActivity = dog.activity.api.GetLadyActivity;
export const GetProgressOfBonusSkin = dog.activity.api.GetProgressOfBonusSkin;
export const UseFreeItemV2 = dog.activity.api.UseFreeItemV2;
export const UsePaidItemV2 = dog.activity.api.UsePaidItemV2;
export const GetLadyActivityV2 = dog.activity.api.GetLadyActivityV2;
export const GetMyBag = dog.activity.api.getMyBag;
export const GetMyBagV2 = dog.activity.api.getMyBagV2;
export const SendItem = dog.activity.api.sendItem;
export const SendItemV2 = dog.activity.api.sendItemV2;
export const EquipItem = dog.activity.api.equipItem;
export const EquipItemV2 = dog.activity.api.equipItemV2;
export const GetNotification = dog.activity.api.getNotification;
export const GetTopFansOfLady = dog.activity.api.getTopFansOfLady;
export const RedeemCode = dog.activity.api.redeemCode;
export const GetDailyPackage = dog.activity.api.getDailyPackage;
export const EDUCATIONDOLLS = dog.activity.proto.JkfPayActivity.Activity.MainType.EDUCATIONDOLLS;
export const LIMITED = dog.activity.proto.JkfPayActivity.ActivityPackage.ActivityPackageType.LIMITED;
export const NORMAL = dog.activity.proto.JkfPayActivity.ActivityPackage.ActivityPackageType.NORMAL;
