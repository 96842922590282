import { GameParts } from './game';
import { GameAlphaTemplate } from './game_alpha_template';
var XmasParts;
(function (XmasParts) {
    XmasParts["snowfall"] = "snowfall";
})(XmasParts || (XmasParts = {}));
const SCORE_TEXT_STYLE = {
    align: 'center',
    fill: 'white',
    fontSize: 21,
    fontWeight: 'bold',
    letterSpacing: 1,
    lineJoin: 'bevel',
    strokeThickness: 2,
};
const GIFT_TEXT_STYLE = {
    align: 'center',
    fill: '#450429',
    fontSize: 22,
    fontWeight: 'bold',
    letterSpacing: 1,
};
const GIFT_PARTS = [
    {
        key: GameParts.gift1,
        position: { x: 90, y: 179 },
        autoPosition: 'fix2',
        giftIndex: 0,
        defaultSkinName: 'normal'
    },
    {
        key: GameParts.gift2,
        position: { x: 90, y: 694 },
        autoPosition: 'fix2',
        giftIndex: 1,
        defaultSkinName: 'normal'
    },
    {
        key: GameParts.gift3,
        position: { x: 630, y: 179 },
        autoPosition: 'fix2',
        giftIndex: 2,
        defaultSkinName: 'normal'
    },
    {
        key: GameParts.gift4,
        position: { x: 630, y: 435 },
        autoPosition: 'fix2',
        giftIndex: 3,
        defaultSkinName: 'normal'
    },
    {
        key: GameParts.gift5,
        position: { x: 630, y: 694 },
        autoPosition: 'fix2',
        giftIndex: 4,
        defaultSkinName: 'normal'
    },
];
export class Xmas2021 extends GameAlphaTemplate {
    constructor() {
        super(...arguments);
        this.mainDefaultSkinName = 'default';
    }
    async init() {
        const app = this.app;
        await new Promise((resolve, reject) => {
            app.loader.add(GameParts.main, 'https://public.hare200.com/activity/5/spine/main.json')
                .add(GameParts.bg, 'https://public.hare200.com/activity/1/spine/background_effect.json')
                .add(XmasParts.snowfall, 'https://public.hare200.com/activity/1/spine/snowfall.json')
                .add(GameParts.scoreBar, 'https://public.hare200.com/activity/5/spine/experience_bar.json')
                .add(GameParts.gift1, 'https://public.hare200.com/activity/5/spine/part1.json')
                .add(GameParts.gift2, 'https://public.hare200.com/activity/5/spine/part2.json')
                .add(GameParts.gift3, 'https://public.hare200.com/activity/5/spine/part3.json')
                .add(GameParts.gift4, 'https://public.hare200.com/activity/5/spine/part4.json')
                .add(GameParts.gift5, 'https://public.hare200.com/activity/5/spine/part5.json');
            app.loader.load((loader, resources) => {
                this.main = this.addMain(GameParts.main, resources, this.mainDefaultSkinName);
                this.main.state.setAnimation(0, 'snow_fall', true);
                this.score.bar = this.addScoreBar(resources);
                this.score.text = this.addScoreText({ x: 380, y: 755 }, SCORE_TEXT_STYLE);
                this.addGifts(resources, GIFT_PARTS);
                this.addGiftNumbers(GIFT_PARTS, { x: 0, y: 0 }, GIFT_TEXT_STYLE);
                // 使zIndex 生效
                app.stage.sortChildren();
                resolve(true);
            });
        });
        await this.fetchActivityDetail();
        return true;
    }
}
