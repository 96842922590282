import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueJSModal from 'vue-js-modal';
import DailyPackage from '@/components/daily-package.vue';
Vue.use(VueJSModal);
import { ModalEvents } from '@/utils/enum';
let DailyPackageEntry = class DailyPackageEntry extends Vue {
    constructor() {
        super(...arguments);
        this.DailyPackageModalName = 'dailypack';
        this.isMobile = (function () {
            var deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
            return deviceWidth <= 480 ? true : false;
        })();
    }
    mounted() {
        const that = this;
        this.$root.$on(ModalEvents.SHOW_DAILY_PACKAGE, function (componentId, packageId, purchaseSuccess) {
            if (that.isMobile && that.componentId === componentId) {
                that.showMobileDailyPackage(packageId, purchaseSuccess);
            }
            else if (that.componentId === componentId) {
                that.showDesktopDailyPackage(packageId, purchaseSuccess);
            }
        });
    }
    beforeDestroy() {
        this.$root.$off(ModalEvents.SHOW_DAILY_PACKAGE);
    }
    showDesktopDailyPackage(packageId, purchaseSuccess) {
        this.$modal.show(DailyPackage, {
            name: this.DailyPackageModalName,
            close: this.closeDailyPackage,
            purchaseSuccess: purchaseSuccess,
            activityId: this.activityId,
            packageId,
            accessToken: this.accessToken
        }, {
            height: 'auto',
            width: 922
        });
    }
    showMobileDailyPackage(packageId, purchaseSuccess) {
        this.$modal.show(DailyPackage, {
            name: this.DailyPackageModalName,
            close: this.closeDailyPackage,
            purchaseSuccess: purchaseSuccess,
            activityId: this.activityId,
            packageId,
            accessToken: this.accessToken
        }, {
            height: 'auto',
            width: '100%',
            classes: 'mobile-modal'
        });
    }
    closeDailyPackage() {
        this.$modal.hide(this.DailyPackageModalName);
    }
};
__decorate([
    Prop({ type: String, required: true })
], DailyPackageEntry.prototype, "componentId", void 0);
__decorate([
    Prop({ type: Number, required: true })
], DailyPackageEntry.prototype, "activityId", void 0);
__decorate([
    Prop()
], DailyPackageEntry.prototype, "accessToken", void 0);
DailyPackageEntry = __decorate([
    Component({
        name: 'ActivityDailyPackageEntry',
    })
], DailyPackageEntry);
export default DailyPackageEntry;
