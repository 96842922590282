import { isGrpcDevtoolsAvailable, enableGrpcDevtools } from '@/api/grpc-tool/grpc-devtools';
const noop = () => void 0;
export const enableGrpcWebDevtools = localStorage.getItem('gubed') === 'true'
    ? typeof __GRPCWEB_DEVTOOLS__ === 'function'
        ? __GRPCWEB_DEVTOOLS__
        : isGrpcDevtoolsAvailable
            ? enableGrpcDevtools
            : noop
    : noop;
if (localStorage.getItem('gubed') === 'true') {
    localStorage.setItem('dogdebug', 'true');
}
else {
    localStorage.removeItem('dogdebug');
}
