import { DonateServicePromiseClient } from '@lctech-tw/jkf-pay-proto/compiled/donate/donate_grpc_web_pb';
import { BuyGiftReq, GetGiftPackagesReq } from '@lctech-tw/jkf-pay-proto/compiled/donate/donate_pb';
import { ActivityServicePromiseClient } from '@lctech-tw/jkf-pay-proto/compiled/activity/activity_grpc_web_pb';
import { BuyPackageReq } from '@lctech-tw/jkf-pay-proto/compiled/activity/activity_pb';
import { enableGrpcWebDevtools } from '@/api/grpc-tool/enable-grpc-web-devtools';
export class DonateService {
    constructor() {
        this.GetGiftPackages = async () => {
            const req = new GetGiftPackagesReq();
            const res = await this.donateClient.getGiftPackages(req);
            return res.toObject().giftPackagesList;
        };
        this.BuyGift = async (params) => {
            const req = new BuyGiftReq();
            req.setAccessToken(params.accessToken);
            req.setAmount(params.amount);
            req.setGa4ClientId(params.ga4ClientId);
            req.setGa4PagePath(params.ga4PagePath);
            req.setGa4PageTitle(params.ga4PageTitle);
            req.setGa4SessionId(params.ga4SessionId);
            req.setGiftId(params.giftPackageId);
            req.setMessage(params.message);
            req.setToObjectId(params.toObjectId);
            await this.donateClient.buyGift(req);
        };
        this.BuyPackage = async (params) => {
            const req = new BuyPackageReq();
            req.setAccessToken(params.accessToken);
            req.setPackageId(params.packageId);
            await this.activityClient.buyPackage(req);
        };
        this.donateClient = new DonateServicePromiseClient(process.env.VUE_APP_DONATE_API_HOST);
        this.activityClient = new ActivityServicePromiseClient(process.env.VUE_APP_DONATE_API_HOST);
        enableGrpcWebDevtools([
            this.donateClient,
            this.activityClient
        ]);
    }
}
