import * as PIXI from 'pixi.js';
export var GameParts;
(function (GameParts) {
    GameParts["default"] = "default";
    GameParts["main"] = "main";
    GameParts["bg"] = "bg";
    GameParts["effect"] = "effect";
    GameParts["scoreBar"] = "scoreBar";
    GameParts["gift1"] = "gift1";
    GameParts["gift2"] = "gift2";
    GameParts["gift3"] = "gift3";
    GameParts["gift4"] = "gift4";
    GameParts["gift5"] = "gift5";
    GameParts["aura"] = "aura";
    GameParts["levelUpEffect"] = "levelUpEffect";
})(GameParts || (GameParts = {}));
export class Progress {
    constructor(payload) {
        this.currentExp = 0;
        this.limitExp = 0;
        this.currentLevel = 0;
        this.limitLevel = 0;
        if (payload) {
            this.currentExp = payload.currentExp;
            this.limitExp = payload.limitExp;
            this.currentLevel = payload.currentLevel;
            this.limitLevel = payload.limitLevel;
        }
    }
}
export const addCenterAlignedTextContainer = (x, y, z, scale, app, text) => {
    const textContainer = new PIXI.Container();
    textContainer.position.set(x * scale, y * scale);
    textContainer.zIndex = z;
    textContainer.scale.set(scale);
    app.stage.addChild(textContainer);
    text.anchor.set(0.5, 0.5);
    textContainer.addChild(text);
    app.stage.addChild(textContainer);
    return textContainer;
};
