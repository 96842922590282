import { Category, ControlPanelImages, Place } from './info';
const gift1 = {
    category: Category.PrimaryGift,
    name: '應援手環',
    icon: {
        url: 'https://public.jkface.net/supports/22/icon_part1.png',
        place: [Place.GameBag, Place.JkfaceGift, Place.JkliveGift, Place.Server]
    },
    iconDisabled: {
        url: 'https://public.jkface.net/supports/22/icon_part1_disable.png',
        place: [Place.GameBag, Place.JkfaceGift]
    },
    iconWithFrame: {
        url: 'https://public.jkface.net/supports/22/btn_part1.png',
        place: [Place.Game]
    },
    iconWithFrameDisabled: {
        url: 'https://public.jkface.net/supports/22/btn_part1_disable.png',
        place: [Place.Game]
    },
    svgaAnimation: {
        url: 'https://public.jkface.net/supports/22/part1.svga',
        place: [Place.JkliveAnimation, Place.Server]
    },
    pngAnimation: {
        url: 'https://public.jkface.net/supports/22/sendgift_part1.png',
        place: [Place.Notification, Place.Server]
    }
};
const gift2 = {
    category: Category.PrimaryGift,
    name: '活力汽水',
    icon: {
        url: 'https://public.jkface.net/supports/22/icon_part2.png',
        place: [Place.GameBag, Place.JkfaceGift, Place.JkliveGift, Place.Server]
    },
    iconDisabled: {
        url: 'https://public.jkface.net/supports/22/icon_part2_disable.png',
        place: [Place.GameBag, Place.JkfaceGift]
    },
    iconWithFrame: {
        url: 'https://public.jkface.net/supports/22/btn_part2.png',
        place: [Place.Game]
    },
    iconWithFrameDisabled: {
        url: 'https://public.jkface.net/supports/22/btn_part2_disable.png',
        place: [Place.Game]
    },
    svgaAnimation: {
        url: 'https://public.jkface.net/supports/22/part2.svga',
        place: [Place.JkliveAnimation, Place.Server]
    },
    pngAnimation: {
        url: 'https://public.jkface.net/supports/22/sendgift_part2.png',
        place: [Place.Notification, Place.Server]
    }
};
const gift3 = {
    category: Category.PrimaryGift,
    name: '加油棒',
    icon: {
        url: 'https://public.jkface.net/supports/22/icon_part3.png',
        place: [Place.GameBag, Place.JkfaceGift, Place.JkliveGift, Place.Server]
    },
    iconDisabled: {
        url: 'https://public.jkface.net/supports/22/icon_part3_disable.png',
        place: [Place.GameBag, Place.JkfaceGift]
    },
    iconWithFrame: {
        url: 'https://public.jkface.net/supports/22/btn_part3.png',
        place: [Place.Game]
    },
    iconWithFrameDisabled: {
        url: 'https://public.jkface.net/supports/22/btn_part3_disable.png',
        place: [Place.Game]
    },
    svgaAnimation: {
        url: 'https://public.jkface.net/supports/22/part3.svga',
        place: [Place.JkliveAnimation, Place.Server]
    },
    pngAnimation: {
        url: 'https://public.jkface.net/supports/22/sendgift_part3.png',
        place: [Place.Notification, Place.Server]
    }
};
const gift4 = {
    category: Category.PrimaryGift,
    name: '衝鋒水槍',
    icon: {
        url: 'https://public.jkface.net/supports/22/icon_part4.png',
        place: [Place.GameBag, Place.JkfaceGift, Place.JkliveGift, Place.Server]
    },
    iconDisabled: {
        url: 'https://public.jkface.net/supports/22/icon_part4_disable.png',
        place: [Place.GameBag, Place.JkfaceGift]
    },
    iconWithFrame: {
        url: 'https://public.jkface.net/supports/22/btn_part4.png',
        place: [Place.Game]
    },
    iconWithFrameDisabled: {
        url: 'https://public.jkface.net/supports/22/btn_part4_disable.png',
        place: [Place.Game]
    },
    svgaAnimation: {
        url: 'https://public.jkface.net/supports/22/part4.svga',
        place: [Place.JkliveAnimation, Place.Server]
    },
    pngAnimation: {
        url: 'https://public.jkface.net/supports/22/sendgift_part4.png',
        place: [Place.Notification, Place.Server]
    }
};
const giftEx = {
    category: Category.PrimaryGiftEx,
    name: '心心相印',
    icon: {
        url: 'https://public.jkface.net/supports/22/icon_part5.png',
        place: [Place.GameBag, Place.JkliveGift, Place.Server]
    },
    iconDisabled: {
        url: 'https://public.jkface.net/supports/22/icon_part5_disable.png',
        place: [Place.GameBag]
    },
    iconWithFrame: {
        url: 'https://public.jkface.net/supports/22/btn_part5.png',
        place: [Place.Game]
    },
    iconWithFrameDisabled: {
        url: 'https://public.jkface.net/supports/22/btn_part5_disable.png',
        place: [Place.Game]
    },
    svgaAnimation: {
        url: 'https://public.jkface.net/supports/22/part5.svga',
        place: [Place.JkliveAnimation, Place.Server]
    },
    pngAnimation: {
        url: 'https://public.jkface.net/supports/22/sendgift_part5.png',
        place: [Place.Notification, Place.Server]
    }
};
const freeGift = {
    category: Category.FreeGift,
    name: '掌聲鼓勵',
    icon: {
        url: 'https://public.jkface.net/supports/22/icon_sendgift1.png',
        place: [Place.JkliveHeart]
    },
    iconWithFrame: {
        url: 'https://public.jkface.net/supports/22/btn_sendgift1.png',
        place: [Place.Game]
    },
    iconWithFrameDisabled: {
        url: 'https://public.jkface.net/supports/22/btn_sendgift1_disable.png',
        place: [Place.Game]
    },
};
const packageGift = {
    category: Category.PackageGift,
    name: '愛的鼓勵',
    iconWithFrame: {
        url: 'https://public.jkface.net/supports/22/btn_sendgift2.png',
        place: [Place.Game]
    },
    iconWithFrameDisabled: {
        url: 'https://public.jkface.net/supports/22/btn_sendgift2_disable.png',
        place: [Place.Game]
    },
};
const packageNormal = {
    category: Category.PackageNormal,
    name: '每日禮包',
    icon: {
        url: 'https://public.jkface.net/supports/22/禮包含字_每日禮包.png',
        place: [Place.JkfaceGift, Place.Server]
    },
    iconWithFrame: {
        url: 'https://public.jkface.net/supports/22/btn_dailygift.png',
        place: [Place.Game]
    },
    iconWithFrameDisabled: {
        url: 'https://public.jkface.net/supports/22/btn_dailygift_disable.png',
        place: [Place.Game]
    }
};
const packageLimited = {
    category: Category.PackageLimited,
    name: '限定禮包',
    icon: {
        url: 'https://public.jkface.net/supports/22/禮包含字_限時禮包.png',
        place: [Place.JkfaceGift, Place.Server]
    },
    iconWithFrame: {
        url: 'https://public.jkface.net/supports/22/btn_limitedgift.png',
        place: [Place.Game]
    },
    iconWithFrameDisabled: {
        url: 'https://public.jkface.net/supports/22/btn_limitedgift_disable.png',
        place: [Place.Game]
    }
};
const skin1 = {
    category: Category.Skin,
    name: '預設泳衣',
    icon: {
        url: 'https://public.jkface.net/supports/22/icon_skin_1.png',
        place: [Place.GameBag, Place.Server]
    },
    iconDisabled: {
        url: 'https://public.jkface.net/supports/22/icon_skin_1_disable.png',
        place: [Place.GameBag]
    },
    pngAnimation: {
        url: 'https://public.jkface.net/supports/22/sendgift_skin1.png',
        place: [Place.Notification]
    }
};
const skin2 = {
    category: Category.Skin,
    name: '紅色泳衣',
    icon: {
        url: 'https://public.jkface.net/supports/22/icon_skin_2.png',
        place: [Place.GameBag, Place.Server]
    },
    iconDisabled: {
        url: 'https://public.jkface.net/supports/22/icon_skin_2_disable.png',
        place: [Place.GameBag]
    },
    pngAnimation: {
        url: 'https://public.jkface.net/supports/22/sendgift_skin2.png',
        place: [Place.Notification]
    }
};
const skin3 = {
    category: Category.Skin,
    name: '金色泳衣',
    icon: {
        url: 'https://public.jkface.net/supports/22/icon_skin_3.png',
        place: [Place.GameBag, Place.Server]
    },
    iconDisabled: {
        url: 'https://public.jkface.net/supports/22/icon_skin_3_disable.png',
        place: [Place.GameBag]
    },
    pngAnimation: {
        url: 'https://public.jkface.net/supports/22/sendgift_skin3.png',
        place: [Place.Notification]
    }
};
const panel = {
    category: Category.Panel,
    name: '遊戲機控制面板',
    panelBackground: {
        url: 'https://public.jkface.net/supports/22/btn_panel.png',
        place: [Place.Game]
    },
    nameBadges: {
        normal: {
            url: 'https://public.jkface.net/supports/22/namepanel.png',
            place: [Place.Game]
        },
        disabled: {
            url: 'https://public.jkface.net/supports/22/namepanel_disable.png',
            place: [Place.Game]
        },
        special: {
            url: 'https://public.jkface.net/supports/22/namepanel2.png',
            place: [Place.Game]
        },
        limited: {
            url: 'https://public.jkface.net/supports/22/namepanel3.png',
            place: [Place.Game]
        },
    },
    // 控制面板風格按鈕
    style: {
        url: 'https://public.jkface.net/supports/22/btn_style.png',
        place: [Place.Game]
    }
};
const info = {
    landingPageUrl: process.env.VUE_APP_STAGE === 'prod' ? 'https://jkface.net/event/2023/springsakura' : 'https://fossa.one/event/2023/springsakura'
};
const controlPanelImages = () => {
    const images = new ControlPanelImages();
    images.nameBadges = {
        normal: panel.nameBadges.normal.url,
        disabled: panel.nameBadges.disabled.url,
        special: panel.nameBadges.special.url,
        limited: panel.nameBadges.limited.url,
    };
    images.freeGift = {
        normal: freeGift.iconWithFrame.url,
        disabled: freeGift.iconWithFrameDisabled.url,
    };
    images.packageGift = {
        normal: packageGift.iconWithFrame.url,
        disabled: packageGift.iconWithFrameDisabled.url,
    };
    images.normalPackage = {
        normal: packageNormal.iconWithFrame.url,
        disabled: packageNormal.iconWithFrameDisabled.url,
    };
    images.limitedPackage = {
        normal: packageLimited.iconWithFrame.url,
        disabled: packageLimited.iconWithFrameDisabled.url,
    };
    images.panelImage = panel.panelBackground.url;
    images.bagImage = panel.style.url;
    images.gifts = {
        1: {
            normal: gift1.iconWithFrame.url,
            disabled: gift1.iconWithFrameDisabled.url
        },
        2: {
            normal: gift2.iconWithFrame.url,
            disabled: gift2.iconWithFrameDisabled.url
        },
        3: {
            normal: gift3.iconWithFrame.url,
            disabled: gift3.iconWithFrameDisabled.url
        },
        4: {
            normal: gift4.iconWithFrame.url,
            disabled: gift4.iconWithFrameDisabled.url
        },
        5: {
            normal: giftEx.iconWithFrame.url,
            disabled: giftEx.iconWithFrameDisabled.url
        },
    };
    return images;
};
export const Spring2023Info = {
    info,
    controlPanelImages
};
