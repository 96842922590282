import * as PIXI from 'pixi.js';
import { Spine } from 'pixi-spine';
import { GameParts, Progress, addCenterAlignedTextContainer } from './game';
import { GetLadyActivity } from '@/api/jkf-pay/activity';
export class GameAlphaTemplate {
    constructor(payload) {
        this.main = undefined;
        this.mainDefaultSkinName = 'main_skin_1';
        this.mainAnimationPrefix = 'wave_lv';
        this.score = { bar: undefined, text: undefined };
        this.gifts = [
            { key: GameParts.gift1, entity: undefined, text: undefined },
            { key: GameParts.gift2, entity: undefined, text: undefined },
            { key: GameParts.gift3, entity: undefined, text: undefined },
            { key: GameParts.gift4, entity: undefined, text: undefined },
            { key: GameParts.gift5, entity: undefined, text: undefined },
        ];
        this.sendGiftEffect = undefined;
        this.sendGiftEffectAnimationName = 'effect_bulb';
        this.progress = new Progress();
        this.addBg = (url) => {
            const app = this.app;
            const bg = new PIXI.Sprite(PIXI.Texture.from(url));
            bg.scale.set(this.scale);
            app.stage.addChild(bg);
            return bg;
        };
        this.addMain = (key, resources, defaultSkin) => {
            const app = this.app;
            const spineData = resources[key].spineData;
            const main = new Spine(spineData);
            main.skeleton.setToSetupPose();
            main.skeleton.setSkinByName(defaultSkin);
            main.position.set(app.screen.width / 2, app.screen.height);
            main.scale.set(this.scale);
            app.stage.addChild(main);
            app.start();
            return main;
        };
        this.addScoreBar = (resources) => {
            const app = this.app;
            // score progress
            const spineData = resources[GameParts.scoreBar].spineData;
            const scoreBarSpine = new Spine(spineData);
            scoreBarSpine.x = app.screen.width * 0.5;
            scoreBarSpine.y = app.screen.height;
            scoreBarSpine.scale.set(this.scale);
            scoreBarSpine.zIndex = 2;
            scoreBarSpine.skeleton.setToSetupPose();
            scoreBarSpine.update(0);
            scoreBarSpine.autoUpdate = true;
            app.stage.addChild(scoreBarSpine);
            app.start();
            return scoreBarSpine;
        };
        this.addScoreText = (position, style) => {
            const app = this.app;
            const scale = this.scale;
            const expText = new PIXI.Text('', new PIXI.TextStyle(style));
            const textContainer = addCenterAlignedTextContainer(position.x, position.y, 2, scale, app, expText);
            app.start();
            return expText;
        };
        this.addGifts = (resources, giftParts) => {
            const app = this.app;
            giftParts.map(giftPart => {
                const scale = this.scale;
                const spineData = resources[giftPart.key].spineData;
                const giftPartSpine = new Spine(spineData);
                giftPartSpine.skeleton.setSkinByName(giftPart.defaultSkinName);
                if (giftPart.isInteractive) {
                    giftPartSpine.cursor = 'pointer';
                    giftPartSpine.interactive = true;
                    giftPartSpine.on('pointerup', () => {
                        this.sendGiftByIndex(giftPart.giftIndex);
                    });
                }
                if (giftPart.autoPosition === 'fix') {
                    giftPartSpine.position.set(0, app.screen.height);
                }
                else if (giftPart.autoPosition === 'fix2') {
                    giftPartSpine.position.set(app.screen.width * 0.5, app.screen.height);
                }
                else {
                    giftPartSpine.position.set((giftPart.position.x) * scale, (giftPart.position.y) * scale);
                }
                giftPartSpine.scale.set(scale);
                app.stage.addChild(giftPartSpine);
                const correspondingGift = this.gifts.find(e => e.key === giftPart.key);
                if (correspondingGift) {
                    correspondingGift.entity = giftPartSpine;
                    correspondingGift.text = this.addGiftNumber(giftPart.position, {});
                }
            });
        };
        this.addGiftNumbers = (giftParts, shift, style) => {
            giftParts.map(giftPart => {
                const correspondingGift = this.gifts.find(e => e.key === giftPart.key);
                if (correspondingGift) {
                    correspondingGift.text = this.addGiftNumber({
                        x: giftPart.position.x + shift.x,
                        y: giftPart.position.y + shift.y
                    }, style);
                }
            });
        };
        this.addGiftNumber = (position, style) => {
            const app = this.app;
            const scale = this.scale;
            const giftNumber = new PIXI.Text('', new PIXI.TextStyle(style));
            const textContainer = addCenterAlignedTextContainer(position.x, position.y, 3, scale, app, giftNumber);
            app.start();
            return giftNumber;
        };
        this.updateScoreBar = (percent) => {
            const scoreBar = this.score.bar;
            const animationName = 'experience_bar';
            if (scoreBar && scoreBar.state.hasAnimation(animationName)) {
                const barAnimation = scoreBar.state.setAnimation(0, animationName, false);
                barAnimation.animationEnd = 4 * percent;
                // 播放速度
                scoreBar.state.timeScale = 300;
            }
        };
        this.app = payload.app;
        this.scale = payload.scale;
        this.activityId = payload.activityId;
        this.ladyObjectId = payload.ladyObjectId;
        this.sendGiftByIndex = payload.sendGiftByIndex;
    }
    async init() {
        return new Promise(resolve => resolve(true));
    }
    updateScore(progress) {
        const currentExp = progress.currentExp;
        const limitExp = progress.limitExp;
        const currentLevel = progress.currentLevel;
        const limitLevel = progress.limitLevel;
        const humanLevel = currentLevel > limitLevel ? 'MAX' : `LV${currentLevel}`;
        const humanLimitExp = limitExp > 0 ? limitExp : 'MAX';
        this.updateScoreBar(limitExp > 0 ? currentExp / limitExp : 1);
        if (this.score.text) {
            this.score.text.text = `${currentExp} / ${humanLimitExp} / ${humanLevel}`;
        }
    }
    async fetchActivityDetail() {
        const res = await GetLadyActivity({ activityId: this.activityId, ladyObjectId: this.ladyObjectId });
        if (this.main?.skeleton.skin !== res.getMainPart()?.getSkinName()) {
            this.changeSkin(this.main, res.getMainPart()?.getSkinName() || '', this.mainDefaultSkinName);
        }
        this.gifts.map(e => {
            if (e.key === GameParts.gift1 && res.getPart1() && e.text && e.entity) {
                e.text.text = `X${res.getPart1()?.getAmount()}`;
                this.changeSkin(e.entity, res.getPart1()?.getSkinName() || '', 'disable');
            }
            else if (e.key === GameParts.gift2 && res.getPart2() && e.text && e.entity) {
                e.text.text = `X${res.getPart2()?.getAmount()}`;
                this.changeSkin(e.entity, res.getPart2()?.getSkinName() || '', 'disable');
            }
            else if (e.key === GameParts.gift3 && res.getPart3() && e.text && e.entity) {
                e.text.text = `X${res.getPart3()?.getAmount()}`;
                this.changeSkin(e.entity, res.getPart3()?.getSkinName() || '', 'disable');
            }
            else if (e.key === GameParts.gift4 && res.getPart4() && e.text && e.entity) {
                e.text.text = `X${res.getPart4()?.getAmount()}`;
                this.changeSkin(e.entity, res.getPart4()?.getSkinName() || '', 'disable');
            }
            else if (e.key === GameParts.gift5 && res.getPart5() && e.text && e.entity) {
                e.text.text = `X${res.getPart5()?.getAmount()}`;
                this.changeSkin(e.entity, res.getPart5()?.getSkinName() || '', 'disable');
            }
        });
        const nextProgress = new Progress({
            currentExp: res.getCurrentPoint(),
            currentLevel: res.getCurrentLevel(),
            limitExp: res.getNextLevelPoint(),
            limitLevel: res.getMaxLevel(),
        });
        this.progress = nextProgress;
        this.updateScore(nextProgress);
        return res;
    }
    changeSkin(spine, skinName, defaultSkin) {
        try {
            if (spine) {
                spine.skeleton.setSkinByName(spine.spineData.findSkin(skinName) ? skinName : defaultSkin);
                spine.skeleton.setSlotsToSetupPose();
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    showSendGiftEffect(point) {
        if (this.sendGiftEffect && this.sendGiftEffect.state.hasAnimation(this.sendGiftEffectAnimationName)) {
            this.sendGiftEffect.alpha = 1;
            this.sendGiftEffect.state.setAnimation(0, this.sendGiftEffectAnimationName, false);
        }
    }
    showLevelUpEffect() {
        // noop
    }
    async refresh() {
        await this.fetchActivityDetail();
    }
}
