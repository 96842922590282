import { DiamondService } from '@/api/jkf-pay/diamond';
import { GetNotification } from '@/api/jkf-pay/activity';
const checkIsValidateMobile = async (accessToken) => {
    try {
        const diamondService = new DiamondService();
        await diamondService.GetDiamondAmount({ accessToken: accessToken });
        return true;
    }
    catch (error) {
        return false;
    }
};
const getUnreadNotification = async (accessToken) => {
    const unreadNotification = await GetNotification(accessToken).then((res) => {
        return {
            giftName: res.getTitle(),
            giftAmount: res.getAmount(),
            giftAnimation: res.getImgUrl(),
            message: res.getMessage(),
            unreadCount: res.getUnreadCount(),
            isAny: res.getUnreadCount() > 0
        };
    });
    // const unreadNotification: UnreadNotification = {
    //   giftAmount: 1,
    //   giftAnimation: "https://local.public.hare200.com/donate/gift/success_candyskin.png",
    //   giftName: "彩虹拐杖糖",
    //   message: "請至 <b>贈送造型</b> 查看收到的造型！",
    //   unreadCount: 3
    // }
    return unreadNotification;
};
const notificationConfig = {
    name: 'notification',
    height: 'auto',
    width: '100%',
    maxWidth: 556,
    scrollable: true,
    classes: 'notification-entry',
};
const activityV3EventBusName = 'activityV3';
export const activityApi = {
    checkIsValidateMobile,
    getUnreadNotification,
    notificationConfig,
    activityV3EventBusName
};
