import * as PIXI from 'pixi.js';
import { Spine } from 'pixi-spine';
import { Progress, addCenterAlignedTextContainer } from './game';
import { GetLadyActivityV2 } from '@/api/jkf-pay/activity';
import { GameAlphaTemplate } from './game_alpha_template';
var DragonBoatParts;
(function (DragonBoatParts) {
    DragonBoatParts["main1"] = "main1";
    DragonBoatParts["main2"] = "main2";
    DragonBoatParts["exp1"] = "exp1";
    DragonBoatParts["exp2"] = "exp2";
    DragonBoatParts["aura"] = "aura";
})(DragonBoatParts || (DragonBoatParts = {}));
export class DragonBoat2021 extends GameAlphaTemplate {
    constructor() {
        super(...arguments);
        this.main1 = undefined;
        this.main1DefaultSkinName = 'main1_skin1';
        this.main2 = undefined;
        this.main2DefaultSkinName = 'main2_skin1';
        this.auras1 = [];
        this.auras2 = [];
        this.exp1 = { bar: undefined, text: undefined };
        this.exp2 = { bar: undefined, text: undefined };
        this.progress1 = new Progress();
        this.progress2 = new Progress();
        this.title = null;
        this.addAuras = (part, resources) => {
            const app = this.app;
            const spineData = resources[DragonBoatParts.aura].spineData;
            const auras = ['', '', '', '', ''].map((e, index) => {
                const aura = new Spine(spineData);
                aura.position.set(app.screen.width / 2, app.screen.height);
                aura.scale.set(this.scale);
                aura.alpha = 0;
                app.stage.addChild(aura);
                app.start();
                return aura;
            });
            return auras;
        };
        this.addTitle = (url) => {
            const app = this.app;
            const title = new PIXI.Sprite(PIXI.Texture.from(url));
            title.scale.set(this.scale * 1.1);
            title.anchor.x = 0.5;
            title.x = app.screen.width / 2;
            title.y = 10;
            app.stage.addChild(title);
            return title;
        };
        this.addCustomScoreBar = (key, resources) => {
            const app = this.app;
            // exp progress
            const spineData = resources[key].spineData;
            const expBar = new Spine(spineData);
            expBar.x = app.screen.width / 2;
            if (key === DragonBoatParts.exp1) {
                expBar.x += 1;
            }
            else {
                expBar.x -= 1;
            }
            expBar.y = app.screen.height - 10;
            expBar.scale.set(this.scale);
            expBar.zIndex = 2;
            expBar.skeleton.setToSetupPose();
            expBar.update(0);
            expBar.autoUpdate = true;
            app.stage.addChild(expBar);
            app.start();
            return expBar;
        };
        this.addCustomScoreText = (key) => {
            const app = this.app;
            const scale = this.scale;
            const position = {
                x: 185, y: 708
            };
            if (key === DragonBoatParts.exp2) {
                position.x = 540;
            }
            const expText = new PIXI.Text('', new PIXI.TextStyle({
                align: 'center',
                fill: 'white',
                fontSize: 21,
                fontWeight: 'bold',
                letterSpacing: 1,
                lineJoin: 'bevel',
                padding: 2,
                stroke: '#1d6d05',
                strokeThickness: 2,
            }));
            const textContainer = addCenterAlignedTextContainer(position.x, position.y, 2, scale, app, expText);
            app.start();
            return expText;
        };
        this.updateCustomScoreBar = (percent, key) => {
            let expBar = this.exp1.bar;
            if (key === DragonBoatParts.exp2) {
                expBar = this.exp2.bar;
            }
            const animationName = 'experience_bar';
            if (expBar &&
                expBar.state.hasAnimation(animationName)) {
                const barAnimation = expBar.state.setAnimation(0, animationName, false);
                barAnimation.animationEnd = 4 * percent;
                // 播放速度
                expBar.state.timeScale = 100;
            }
        };
    }
    async init() {
        const app = this.app;
        await new Promise((resolve, reject) => {
            app.loader
                .add(DragonBoatParts.main1, 'https://public.hare200.com/activity/3/spine/main1_export.json')
                .add(DragonBoatParts.main2, 'https://public.hare200.com/activity/3/spine/main2_export.json')
                .add(DragonBoatParts.exp1, 'https://public.hare200.com/activity/3/spine/experience_bar_main1.json')
                .add(DragonBoatParts.exp2, 'https://public.hare200.com/activity/3/spine/experience_bar_main2.json')
                .add(DragonBoatParts.aura, 'https://public.hare200.com/activity/3/spine/bubble.json');
            app.loader.load((loader, resources) => {
                this.addBg('https://public.hare200.com/activity/3/background.png');
                this.main1 = this.addMain(DragonBoatParts.main1, resources, this.main1DefaultSkinName);
                this.main1.state.setAnimation(0, 'main1_animation', true);
                this.main2 = this.addMain(DragonBoatParts.main2, resources, this.main2DefaultSkinName);
                this.main2.state.setAnimation(0, 'animation', true);
                this.exp1.bar = this.addCustomScoreBar(DragonBoatParts.exp1, resources);
                this.exp2.bar = this.addCustomScoreBar(DragonBoatParts.exp2, resources);
                this.exp1.text = this.addCustomScoreText(DragonBoatParts.exp1);
                this.exp2.text = this.addCustomScoreText(DragonBoatParts.exp2);
                this.auras1 = this.addAuras(DragonBoatParts.main1, resources);
                this.auras2 = this.addAuras(DragonBoatParts.main2, resources);
                // 使zIndex 生效
                app.stage.sortChildren();
                resolve(true);
            });
        });
        await this.fetchActivityDetailCustom();
        return true;
    }
    updateCustomScore(progress, key) {
        const currentExp = progress.currentExp;
        const limitExp = progress.limitExp;
        const currentLevel = progress.currentLevel;
        const limitLevel = progress.limitLevel;
        const humanLevel = currentLevel > limitLevel ? 'MAX' : `LV${currentLevel}`;
        const humanLimitExp = limitExp > 0 ? limitExp : 'MAX';
        this.updateCustomScoreBar(limitExp > 0 ? currentExp / limitExp : 1, key);
        if (key === DragonBoatParts.exp1) {
            if (this.exp1.text) {
                this.exp1.text.text = `${currentExp} / ${humanLimitExp} / ${humanLevel}`;
            }
        }
        else {
            if (this.exp2.text) {
                this.exp2.text.text = `${currentExp} / ${humanLimitExp} / ${humanLevel}`;
            }
        }
    }
    async fetchActivityDetailCustom() {
        const res = await GetLadyActivityV2({ activityId: this.activityId, ladyObjectId: this.ladyObjectId });
        res.getMainList().map(e => {
            if (e.getTargetId() === 1) {
                e.getItemsList().map(i => {
                    let main1Skin = 'main1_skin1';
                    switch (i.getSkinName()) {
                        case 'main_blue':
                            main1Skin = 'main1_skin2';
                            break;
                        case 'main_black':
                            main1Skin = 'main1_skin3';
                            break;
                        default:
                            break;
                    }
                    this.changeSkin(this.main1, main1Skin, this.main1DefaultSkinName);
                });
            }
            else if (e.getTargetId() === 2) {
                e.getItemsList().map(i => {
                    let main2Skin = 'main2_skin1';
                    switch (i.getSkinName()) {
                        case 'main_blue':
                            main2Skin = 'main2_skin2';
                            break;
                        case 'main_black':
                            main2Skin = 'main2_skin3';
                            break;
                        default:
                            break;
                    }
                    this.changeSkin(this.main2, main2Skin, this.main2DefaultSkinName);
                });
            }
        });
        res.getTargetsList().map(e => {
            const levelInfo = e.getLevelInfo();
            if (levelInfo) {
                const nextProgress = new Progress({
                    currentExp: levelInfo.getCurrentPoint(),
                    currentLevel: levelInfo.getCurrentLevel(),
                    limitExp: levelInfo.getNextLevelPoint(),
                    limitLevel: res.getMaxLevel(),
                });
                const auraslength = levelInfo.getDecorationsList().length;
                if (e.getTargetId() === 1) {
                    this.progress1 = nextProgress;
                    this.updateCustomScore(nextProgress, DragonBoatParts.exp1);
                    this.auras1.map((i, index) => {
                        if (index < auraslength) {
                            i.alpha = 1;
                            i.state.setAnimation(0, `main1_bubble_${index + 1}`, true);
                        }
                    });
                }
                else if (e.getTargetId() === 2) {
                    this.progress2 = nextProgress;
                    this.updateCustomScore(nextProgress, DragonBoatParts.exp2);
                    this.auras2.map((i, index) => {
                        if (index < auraslength) {
                            i.alpha = 1;
                            i.state.setAnimation(0, `main2_bubble_${index + 1}`, true);
                        }
                    });
                }
            }
        });
        res.getLady()?.getDecorationsList().map(e => {
            this.title?.destroy();
            if (e === '絕代佳人') {
                this.title = this.addTitle('https://resource.jkf.net/activity/3/title_1.png');
            }
            else if (e === '百媚千嬌') {
                this.title = this.addTitle('https://resource.jkf.net/activity/3/title_2.png');
            }
            else if (e === '沉魚落雁') {
                this.title = this.addTitle('https://resource.jkf.net/activity/3/title_3.png');
            }
            else if (e === '國色天香') {
                this.title = this.addTitle('https://resource.jkf.net/activity/3/title_4.png');
            }
            else if (e === '傾國傾城') {
                this.title = this.addTitle('https://resource.jkf.net/activity/3/title_5.png');
            }
        });
    }
    async refresh() {
        await this.fetchActivityDetailCustom();
    }
}
