import Vue from 'vue';
import VueRouter from 'vue-router';
import Activity from '@/views/Activity.vue';
import All from '@/views/All.vue';
Vue.use(VueRouter);
const routes = [
    // 開發用活動總覽頁面
    {
        path: '/all',
        name: 'All',
        component: All
    },
    // 遊戲機實際部署路由
    {
        path: '/:id',
        name: 'Activity',
        component: Activity
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
export default router;
