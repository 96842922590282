import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueJSModal from 'vue-js-modal';
Vue.use(VueJSModal);
let DonationSuccess = class DonationSuccess extends Vue {
    close() {
        // @ts-ignore
        this.$modal.hide(this.$parent.name);
    }
};
__decorate([
    Prop({ required: true })
], DonationSuccess.prototype, "giftIcon", void 0);
__decorate([
    Prop({ required: true })
], DonationSuccess.prototype, "giftAmount", void 0);
DonationSuccess = __decorate([
    Component({
        name: 'ActivityDonationSuccess',
    })
], DonationSuccess);
export default DonationSuccess;
