import { __decorate } from "tslib";
const toHHMMSS = (secs) => {
    if (secs > 0) {
        var hours = Math.floor(secs / 3600);
        var minutes = Math.floor(secs / 60) % 60;
        var seconds = secs % 60;
        return [hours, minutes, seconds]
            .map((v) => (v < 10 ? '0' + v : v))
            .filter((v, i) => v !== '00' || i > 0)
            .join(':');
    }
    else {
        return '';
    }
};
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ControlButton from '@/components/activityV3/control-buttonV3.vue';
import { GetInfoByID, GetMyFreeItem, GetMyPaidItem, GetPaidItemInfo, GetProgressOfBonusSkin, UseFreeItemV2, UsePaidItemV2, GetLadyActivity, LIMITED, NORMAL, } from '@/api/jkf-pay/activity';
import { Throttle } from '@/utils/decorator';
import { showBag, showDailyPackage, showDonation } from '@/utils/modal_api';
import { ControlPanelImages } from '@/game/info';
import { activityApi } from '@/utils/activity';
let ControlPanelV3 = class ControlPanelV3 extends Vue {
    constructor() {
        super(...arguments);
        this.isOngoing = false;
        this.gifts = [];
        this.freeGift = {
            amount: 0,
            cd: 0,
            maxAmount: 10
        };
        this.packageGift = {
            amount: 0,
        };
        this.packageInfo = {
            name: '',
            cd: 0,
            canBuy: false,
            isLimitedPackage: false,
            packageId: 0,
        };
        this.timer = -1;
        this.loadFreeItemTimer = -1;
        this.mainSkinTimer = -1;
        this.isValidateMobile = false;
        this.freeGiftName = '';
        this.paidGiftName = '';
        this.getMainSkinName = () => {
            return GetLadyActivity({ activityId: this.activityId, ladyObjectId: this.ladyObjectId })
                .then((res) => {
                res.getMainPart()?.getSkinName();
                return res.getMainPart()?.getSkinName();
            });
        };
    }
    get modalId() {
        return `${this.componentId}${this.ladyObjectId}${this.activityId}`;
    }
    get freeGiftText() {
        if (this.isOngoing) {
            return toHHMMSS(this.freeGift.cd);
        }
        else {
            return '';
        }
    }
    get isFreeGiftInCooldown() {
        const freeGift = this.freeGift;
        return freeGift.amount > 0 ? false : freeGift.cd > 0;
    }
    get isFreeGiftZero() {
        return this.freeGift.amount === 0;
    }
    get isPackageGiftZero() {
        return this.packageGift.amount === 0;
    }
    get isBuyPackageInCooldown() {
        const info = this.packageInfo;
        return info.canBuy ? false : info.cd > 0;
    }
    get packageGiftText() {
        if (this.isOngoing) {
            if (this.packageGift.amount > 0) {
                return this.packageGift.amount.toString();
            }
            else if (this.isBuyPackageInCooldown) {
                return toHHMMSS(this.packageInfo.cd);
            }
            else {
                return '贈送';
            }
        }
        else {
            return '';
        }
    }
    get packageImage() {
        if (this.packageInfo.isLimitedPackage) {
            return this.panelImages.limitedPackage.normal;
        }
        else if (this.packageInfo.canBuy) {
            return this.panelImages.normalPackage.normal;
        }
        else {
            return this.panelImages.normalPackage.disabled;
        }
    }
    get packageText() {
        if (this.isOngoing) {
            if (this.packageInfo.canBuy) {
                return this.packageInfo.name;
            }
            else {
                return toHHMMSS(this.packageInfo.cd);
            }
        }
        else {
            return '';
        }
    }
    get isPackageGiftInCooldown() {
        if (!this.packageInfo.canBuy) {
            return this.packageGift.amount === 0 && this.packageInfo.cd > 0;
        }
        return false;
    }
    accessTokenChanged() {
        this.refresh();
    }
    activityIdChanged() {
        this.refresh();
    }
    ladyObjectIdChanged() {
        this.refresh();
    }
    async beforeMount() {
        const res = await GetInfoByID(this.activityId);
        this.freeGiftName = res.toObject()?.freeItem?.name ?? '';
        this.paidGiftName = res.toObject().paidItem?.name ?? '';
        const current = new Date().getTime() / 1000;
        const startAt = res.getStartAt()?.getSeconds() || 0;
        const endAt = res.getEndAt()?.getSeconds() || 0;
        let mainSkinName = await this.getMainSkinName();
        if (current > startAt && current < endAt) {
            this.isOngoing = true;
            this.timer = setInterval(() => {
                this.updateFreeItem();
                this.updatePackageInfo();
                // @ts-ignore
                if (this._isDestroyed) {
                    clearInterval(this.timer);
                }
            }, 1000);
            this.loadFreeItemTimer = setInterval(() => {
                this.loadFreeItem();
                // @ts-ignore
                if (this._isDestroyed) {
                    clearInterval(this.loadFreeItemTimer);
                }
            }, 30 * 1000);
            this.mainSkinTimer = setInterval(() => {
                this.getMainSkinName()
                    .then((res) => {
                    if (mainSkinName !== res) {
                        mainSkinName = res;
                        this.$emit('control', 'refresh');
                    }
                });
                // @ts-ignore
                if (this._isDestroyed) {
                    clearInterval(this.mainSkinTimer);
                }
            }, 60 * 1000);
        }
        this.gifts = res
            .getGiftList()
            .slice(0, 4)
            .map((o) => {
            return {
                itemId: o.getItemId(),
                giftId: o.getGiftId(),
                img: o.getItem()?.getImgUrl() || '',
                name: o.toArray()[2][0],
                counter: 0,
            };
        });
        this.refresh();
    }
    mounted() {
        this.$root.$off('sendGiftByIndex');
        this.$root.$on('sendGiftByIndex', this.sendGiftByIndex);
        this.refresh();
        const getAuthedtimer = setInterval(() => {
            if (this.isAuthed()) {
                this.checkIsValidateMobile();
                clearInterval(getAuthedtimer);
            }
        }, 300);
    }
    beforeDestroy() {
        this.$root.$off('sendGiftByIndex');
        clearInterval(this.timer);
        this.timer = -1;
        clearInterval(this.loadFreeItemTimer);
        this.loadFreeItemTimer = -1;
        clearInterval(this.mainSkinTimer);
        this.mainSkinTimer = -1;
    }
    canControl() {
        return this.isValidateMobile && this.isAuthed();
    }
    isAuthed() {
        return this.accessToken !== undefined && this.accessToken !== '';
    }
    loadFreeItem() {
        if (this.canControl()) {
            GetMyFreeItem({
                accessToken: this.accessToken,
                activityId: this.activityId,
            }).then((res) => {
                this.freeGift.amount = res.getAmount();
                const cd = res.getNextCooldownSeconds();
                this.freeGift.cd = cd;
            });
        }
    }
    loadPackageGiftAndPackageInfo() {
        if (this.canControl()) {
            // 取得禮包禮物數量
            GetMyPaidItem({
                accessToken: this.accessToken,
                activityId: this.activityId,
            }).then((res) => {
                this.packageGift.amount = res.getAmount();
            });
            // 取得禮包數量
            GetPaidItemInfo({
                accessToken: this.accessToken,
                activityId: this.activityId,
            }).then((res) => {
                const packages = res.getPackagesList();
                // 可購買的限定禮包
                const buyableLimitedPackage = packages.find((o) => o.getType() === LIMITED && o.getCanBuy());
                // 如果存在可購買的限定禮包，填入限定禮包資料，否則取普通禮包資料
                if (buyableLimitedPackage) {
                    this.packageInfo.isLimitedPackage = true;
                    this.packageInfo.name = buyableLimitedPackage.getName();
                    this.packageInfo.cd = buyableLimitedPackage.getCooldownSeconds();
                    this.packageInfo.canBuy = true;
                    this.packageInfo.packageId = buyableLimitedPackage.getPackageId();
                }
                else {
                    const normalPackage = packages.find((e) => e.getType() === NORMAL);
                    if (normalPackage) {
                        this.packageInfo.isLimitedPackage = false;
                        this.packageInfo.name = normalPackage.getName();
                        this.packageInfo.cd = normalPackage.getCooldownSeconds();
                        this.packageInfo.canBuy = normalPackage.getCanBuy();
                        this.packageInfo.packageId = normalPackage.getPackageId();
                    }
                }
            });
        }
    }
    async loadProgressOfBonusSkin() {
        if (this.canControl()) {
            const res = await GetProgressOfBonusSkin({
                accessToken: this.accessToken,
                activityId: this.activityId,
            });
            res.getProgressesList().map((e) => {
                const gift = this.gifts.find((o) => o.itemId === e.getItemId());
                if (gift) {
                    const counter = e.getThresholdAmount() - e.getCurrentAmount();
                    gift.counter = counter > 0 ? counter : 0;
                }
            });
        }
    }
    refresh() {
        if (this.isOngoing) {
            this.loadFreeItem();
            this.loadPackageGiftAndPackageInfo();
            this.loadProgressOfBonusSkin();
        }
    }
    updateFreeItem() {
        const freeGift = this.freeGift;
        // freeGift數量到達上限時，cd會拿到0或負數，不需處理
        if (freeGift.maxAmount === freeGift.amount)
            return;
        if (freeGift.cd > 0) {
            freeGift.cd--;
        }
        else {
            setTimeout(() => {
                this.loadFreeItem();
            }, 300);
        }
    }
    buyPackage() {
        if (!this.canControl()) {
            this.checkIsValidateMobile();
            return;
        }
        if (!this.isOngoing ||
            this.isBuyPackageInCooldown ||
            !this.packageInfo.canBuy) {
            return;
        }
        showDailyPackage(this, this.modalId, this.packageInfo.packageId, () => {
            this.loadPackageGiftAndPackageInfo();
        });
    }
    openBackpack() {
        if (!this.canControl()) {
            this.checkIsValidateMobile();
            return;
        }
        showBag(this, this.modalId, () => {
            this.$emit('control', 'refresh');
        }, () => {
            this.$emit('control', 'refresh');
        });
    }
    sendGiftByIndex(index) {
        const gift = this.gifts[index];
        if (gift) {
            this.sendGift(gift.giftId);
        }
    }
    sendGift(id) {
        if (!this.canControl()) {
            this.checkIsValidateMobile();
            return;
        }
        if (this.isOngoing) {
            showDonation(this, this.modalId, id, () => {
                this.$emit('control', 'gift');
                this.loadProgressOfBonusSkin();
            });
        }
    }
    sendPackageGift() {
        if (!this.canControl()) {
            this.checkIsValidateMobile();
            return;
        }
        if (!this.isOngoing) {
            return;
        }
        if (this.packageGift.amount < 1) {
            this.buyPackage();
            return;
        }
        UsePaidItemV2({
            accessToken: this.accessToken,
            activityId: this.activityId,
            ladyObjectId: this.ladyObjectId,
            targetId: 0,
            amount: this.packageGift.amount,
        }).then((res) => {
            const amount = res.getAmount();
            const receivePoint = res.getReceivePoint();
            this.packageGift.amount = amount;
            this.$emit('control', 'paid', receivePoint);
            if (amount === 0) {
                this.loadPackageGiftAndPackageInfo();
            }
        });
    }
    sendFreeGift() {
        if (!this.canControl()) {
            this.checkIsValidateMobile();
            return;
        }
        if (!this.isOngoing) {
            return;
        }
        if (this.freeGift.amount < 1) {
            return;
        }
        UseFreeItemV2({
            accessToken: this.accessToken,
            activityId: this.activityId,
            ladyObjectId: this.ladyObjectId,
            targetId: 0,
            amount: 1,
        }).then((res) => {
            const amount = res.getAmount();
            const receivePoint = res.getReceivePoint();
            if (amount === 0 || !this.isFreeGiftInCooldown) {
                this.loadFreeItem();
            }
            else {
                this.freeGift.amount = amount;
            }
            this.$emit('control', 'free', receivePoint);
        });
    }
    updatePackageInfo() {
        const info = this.packageInfo;
        if (info.cd > 0) {
            info.cd--;
        }
        else {
            setTimeout(() => {
                this.loadPackageGiftAndPackageInfo();
            }, 300);
        }
    }
    async checkIsValidateMobile() {
        if (!this.isAuthed()) {
            this.$root.$emit(activityApi.activityV3EventBusName + this.activityId, 'login');
        }
        else {
            this.isValidateMobile = await activityApi.checkIsValidateMobile(this.accessToken);
            if (this.isValidateMobile) {
                this.refresh();
            }
            else {
                this.$root.$emit(activityApi.activityV3EventBusName + this.activityId, 'validate');
            }
        }
    }
    getGiftsImg(index) {
        let innerIndex = (index + 1);
        return this.panelImages.gifts[innerIndex].normal;
    }
};
__decorate([
    Prop()
], ControlPanelV3.prototype, "accessToken", void 0);
__decorate([
    Prop()
], ControlPanelV3.prototype, "ladyObjectId", void 0);
__decorate([
    Prop()
], ControlPanelV3.prototype, "activityId", void 0);
__decorate([
    Prop({ type: String, default: Date.now().toString() })
], ControlPanelV3.prototype, "componentId", void 0);
__decorate([
    Prop({ type: Object, default: new ControlPanelImages() })
], ControlPanelV3.prototype, "panelImages", void 0);
__decorate([
    Watch('accessToken')
], ControlPanelV3.prototype, "accessTokenChanged", null);
__decorate([
    Watch('activityId')
], ControlPanelV3.prototype, "activityIdChanged", null);
__decorate([
    Watch('ladyObjectId')
], ControlPanelV3.prototype, "ladyObjectIdChanged", null);
__decorate([
    Throttle()
], ControlPanelV3.prototype, "buyPackage", null);
__decorate([
    Throttle()
], ControlPanelV3.prototype, "openBackpack", null);
__decorate([
    Throttle()
], ControlPanelV3.prototype, "sendGift", null);
__decorate([
    Throttle()
], ControlPanelV3.prototype, "sendPackageGift", null);
__decorate([
    Throttle()
], ControlPanelV3.prototype, "sendFreeGift", null);
ControlPanelV3 = __decorate([
    Component({
        name: 'ActivityControlPanelV3',
        components: {
            ControlButton,
        },
    })
], ControlPanelV3);
export default ControlPanelV3;
