import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Noty from 'noty';
import { GetDailyPackage } from '@/api/jkf-pay/activity';
import { DiamondService } from '@/api/jkf-pay/diamond';
import { DonateService } from '@/api/jkf-pay/donate';
import NotificationVue from '@/components/notification.vue';
import { activityApi } from '@/utils/activity';
const defaultPkg = {
    id: 0,
    name: '',
    icon: '',
    price: 0,
    description: '',
};
var Steps;
(function (Steps) {
    Steps[Steps["CHECK_BALANCE"] = 0] = "CHECK_BALANCE";
    Steps[Steps["CHARGE"] = 1] = "CHARGE";
})(Steps || (Steps = {}));
let DailyPackage = class DailyPackage extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.isAffordable = false;
        this.myBalance = 0;
        this.step = Steps.CHECK_BALANCE;
        this.dailyPackage = defaultPkg;
    }
    get isCharging() {
        return this.step === Steps.CHARGE;
    }
    mounted() {
        this.init();
    }
    async init() {
        this.isLoading = true;
        this.dailyPackage =
            (await this.getDailyPackage(this.packageId)) || defaultPkg;
        await this.checkBalance();
        this.isLoading = false;
    }
    async getDailyPackage(packageId) {
        const accessToken = this.accessToken;
        return await GetDailyPackage(accessToken, this.activityId).then((res) => {
            const item = res.find((o) => o.getPackageId() === packageId);
            if (item) {
                return {
                    id: item.getPackageId(),
                    name: item.getName(),
                    description: item.getDescription(),
                    icon: item.getImgUrl(),
                    price: item.getDiamondPrice(),
                };
            }
            return undefined;
        });
    }
    async checkBalance() {
        const diamondService = new DiamondService();
        this.myBalance = await diamondService.GetDiamondAmount({
            accessToken: this.accessToken
        });
        this.isAffordable =
            this.dailyPackage != null && this.myBalance >= this.dailyPackage.price;
        this.step = Steps.CHECK_BALANCE;
    }
    async charge() {
        const accessToken = this.accessToken;
        this.myBalance = 0;
        this.step = Steps.CHARGE;
        parent.postMessage({ action: 'supportPay', payload: null }, '*');
        this.close();
    }
    async chargeSuccess() {
        const that = this;
        this.isLoading = true;
        await this.checkBalance();
        setTimeout(() => {
            new Noty({ text: '鑽石儲值成功' }).show();
            this.isLoading = false;
        }, 1000);
    }
    async chargeFail() {
        const that = this;
        this.isLoading = true;
        await this.checkBalance();
        setTimeout(() => {
            new Noty({ text: '鑽石儲值失敗' }).show();
            this.isLoading = false;
        }, 1000);
    }
    async checkout() {
        try {
            if (!this.dailyPackage) {
                return;
            }
            this.isLoading = true;
            const accessToken = this.accessToken;
            const donateService = new DonateService();
            await donateService.BuyPackage({ accessToken: accessToken, packageId: this.dailyPackage.id });
            parent.postMessage({ action: 'supportDonate', payload: null }, '*');
            new Noty({ text: '購買成功' }).show();
            this.isLoading = false;
            this.purchaseSuccess();
            // @ts-ignore
            this.$modal.hide(this.$parent.name);
            this.showNotification();
        }
        catch (error) {
            this.isLoading = false;
            this.checkBalance();
            new Noty({ text: '購買失敗，請重試' }).show();
        }
    }
    async showNotification() {
        const accessToken = this.accessToken;
        const unreadNotification = await activityApi.getUnreadNotification(accessToken);
        if (unreadNotification.isAny) {
            this.$modal.show(NotificationVue, {
                originUnreadNotification: unreadNotification,
                accessToken: accessToken,
            }, activityApi.notificationConfig);
        }
    }
    close() {
        this.$emit('close');
    }
};
__decorate([
    Prop({ type: Number, required: true })
], DailyPackage.prototype, "activityId", void 0);
__decorate([
    Prop({ type: Number, required: true })
], DailyPackage.prototype, "packageId", void 0);
__decorate([
    Prop({ type: Function, default: function () { } })
], DailyPackage.prototype, "purchaseSuccess", void 0);
__decorate([
    Prop()
], DailyPackage.prototype, "accessToken", void 0);
DailyPackage = __decorate([
    Component({
        name: 'ActivityDailyPackage',
    })
], DailyPackage);
export default DailyPackage;
