import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let ControlButton = class ControlButton extends Vue {
    get style() {
        return {
            'background-image': `url('${this.border}')`,
        };
    }
};
__decorate([
    Prop()
], ControlButton.prototype, "border", void 0);
__decorate([
    Prop()
], ControlButton.prototype, "img", void 0);
__decorate([
    Prop()
], ControlButton.prototype, "text", void 0);
__decorate([
    Prop()
], ControlButton.prototype, "ribbon", void 0);
__decorate([
    Prop()
], ControlButton.prototype, "labelImg", void 0);
__decorate([
    Prop({ default: 0 })
], ControlButton.prototype, "badge", void 0);
ControlButton = __decorate([
    Component({
        name: 'GpgControlButton',
    })
], ControlButton);
export default ControlButton;
