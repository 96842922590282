import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let ControlButtonV3 = class ControlButtonV3 extends Vue {
};
__decorate([
    Prop()
], ControlButtonV3.prototype, "img", void 0);
__decorate([
    Prop()
], ControlButtonV3.prototype, "text", void 0);
__decorate([
    Prop()
], ControlButtonV3.prototype, "nameBadgeUrl", void 0);
__decorate([
    Prop({ default: 0 })
], ControlButtonV3.prototype, "counter", void 0);
__decorate([
    Prop()
], ControlButtonV3.prototype, "centerText", void 0);
ControlButtonV3 = __decorate([
    Component({
        name: 'ControlButtonV3',
    })
], ControlButtonV3);
export default ControlButtonV3;
