import { __decorate } from "tslib";
import Noty from 'noty';
import { Vue, Component, Prop } from 'vue-property-decorator';
import DonationSuccess from './donation-success.vue';
import NotificationVue from './notification.vue';
import VueJSModal from 'vue-js-modal';
import { GetNotification } from '@/api/jkf-pay/activity';
import { DonateService } from '@/api/jkf-pay/donate';
import { DiamondService } from '@/api/jkf-pay/diamond';
import Cookies from 'js-cookie';
Vue.use(VueJSModal);
Noty.overrideDefaults({
    layout: 'bottomCenter',
    progressBar: false,
    timeout: 6000,
    theme: 'jkforum',
});
var Steps;
(function (Steps) {
    Steps[Steps["SelectItem"] = 0] = "SelectItem";
    Steps[Steps["CheckBalance"] = 1] = "CheckBalance";
    Steps[Steps["Charge"] = 2] = "Charge";
})(Steps || (Steps = {}));
let DonationForm = class DonationForm extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.isAffordable = false;
        this.giftAmount = 1;
        this.message = '';
        this.myBalance = 0;
        this.items = [];
        this.step = Steps.CheckBalance;
        this.pointsMap = {
            香包: 500,
            髮簪: 2000,
            燒酒: 4000,
            油傘: 10000,
        };
        this.donateService = new DonateService();
    }
    get selectedItem() {
        const id = this.selectedGiftPackageId;
        return (this.items.find((e) => e.id === id) || {
            id: -1,
            name: '',
            icon: '',
            price: 0,
            animation: '',
        });
    }
    get isCharging() {
        return this.step === Steps.Charge;
    }
    mounted() {
        this.init();
    }
    beforeDestroy() {
        this.clearUndoneDonation();
    }
    async init() {
        this.isLoading = true;
        this.items = await this.getItems();
        await this.checkBalance();
        this.isLoading = false;
    }
    async getItems() {
        const items = await this.donateService.GetGiftPackages().then(res => {
            return res.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    icon: item.img,
                    price: item.diamondPrice,
                    animation: item.animation,
                };
            });
        });
        return new Promise((resolve) => {
            resolve(items);
        });
    }
    async checkBalance() {
        const diamondService = new DiamondService();
        this.myBalance = await diamondService.GetDiamondAmount({
            accessToken: this.accessToken
        });
        this.isAffordable =
            this.myBalance >= this.selectedItem.price * this.giftAmount;
        this.step = Steps.CheckBalance;
    }
    async checkout() {
        try {
            this.isLoading = true;
            const accessToken = this.accessToken;
            const giftPackageId = this.selectedGiftPackageId;
            const selectedItem = this.selectedItem;
            const message = this.message;
            const toObjectId = this.currentLadyObjectId;
            const amount = this.giftAmount;
            const ga4ClientId = Cookies.get('_ga') ?? '';
            const ga4ParamsJson = sessionStorage.getItem('ga4Params');
            let ga4SessionId = '';
            let ga4PagePath = '';
            let ga4PageTitle = '';
            if (typeof ga4ParamsJson === 'string') {
                const ga4Params = JSON.parse(ga4ParamsJson);
                ga4SessionId = ga4Params?.sessionId ?? '';
                ga4PagePath = ga4Params?.pagePath ?? '';
                ga4PageTitle = ga4Params?.pageTitle ?? '';
            }
            await this.donateService.BuyGift({
                accessToken,
                giftPackageId,
                message,
                toObjectId,
                amount,
                ga4ClientId,
                ga4PagePath,
                ga4PageTitle,
                ga4SessionId,
            });
            this.showSuccess(selectedItem.animation, this.giftAmount);
            this.donateSuccess({
                '1': { points: this.pointsMap[this.selectedItem.name] },
                '2': { points: this.pointsMap[this.selectedItem.name] },
            });
            parent.postMessage({ action: 'supportDonate', payload: null }, '*');
            const _giftInfo = this.items.find(item => item.id === giftPackageId);
            const giftInfo = _giftInfo ? { ..._giftInfo, id: JSON.stringify(_giftInfo.id), amount: this.giftAmount } : undefined;
            parent.postMessage({ action: 'supportGiftInfo', payload: giftInfo }, '*');
            this.isLoading = false;
            this.close();
        }
        catch (error) {
            this.isLoading = false;
            console.log('checkout error: ', error);
            this.checkBalance();
            new Noty({ text: '結帳失敗，請重試' }).show();
        }
    }
    async charge() {
        this.myBalance = 0;
        this.step = Steps.Charge;
        this.saveUndoneDonation();
        parent.postMessage({ action: 'supportPay', payload: null }, '*');
        this.close();
    }
    async chargeSuccess() {
        const that = this;
        this.isLoading = true;
        await this.checkBalance();
        setTimeout(() => {
            new Noty({ text: '鑽石儲值成功' }).show();
            this.isLoading = false;
        }, 1000);
    }
    async chargeFail() {
        const that = this;
        this.isLoading = true;
        await this.checkBalance();
        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    }
    saveUndoneDonation() {
        localStorage.setItem(this.saveName, JSON.stringify({
            selectedGiftPackageId: this.selectedGiftPackageId,
            message: this.message,
            giftAmount: this.giftAmount,
        }));
    }
    clearUndoneDonation() {
        localStorage.removeItem(this.saveName);
    }
    showSuccess(giftIcon = '', giftAmount = 0) {
        this.close();
        this.$modal.show(DonationSuccess, {
            giftIcon: giftIcon,
            giftAmount: giftAmount,
        }, {
            name: 'donation-success',
            classes: 'donation-success',
            height: 'auto',
            width: '100%',
            maxWidth: 556,
        }, {
            'before-open': this.beforeOpen,
            'before-close': this.beforeClose,
            closed: this.showNotification,
        });
    }
    async showNotification() {
        const that = this;
        const accessToken = this.accessToken;
        const unreadNotification = await GetNotification(accessToken).then((res) => {
            return {
                giftName: res.getTitle(),
                giftAmount: res.getAmount(),
                giftAnimation: res.getImgUrl(),
                message: res.getMessage(),
                unreadCount: res.getUnreadCount(),
            };
        });
        // const unreadNotification: UnreadNotification = {
        //   giftAmount: 1,
        //   giftAnimation: "https://develop.public.hare200.com/donate/gift/success_candyskin.png",
        //   giftName: "彩虹拐杖糖",
        //   message: "請至 <b>贈送造型</b> 查看收到的造型！",
        //   unreadCount: 3
        // }
        if (unreadNotification.unreadCount > 0) {
            this.$modal.show(NotificationVue, {
                originUnreadNotification: unreadNotification,
                accessToken: that.accessToken,
            }, {
                name: 'notification',
                height: 'auto',
                width: '100%',
                maxWidth: 556,
                scrollable: true,
                classes: 'notification-entry',
            }, {
                'before-open': this.beforeOpen,
                'before-close': this.beforeClose,
            });
        }
    }
    close() {
        this.$emit('close');
    }
    beforeOpen() {
        let bodyDOM = document.querySelector('body');
        // @ts-ignore
        bodyDOM.classList.add('showing-modal');
    }
    beforeClose() {
        let bodyDOM = document.querySelector('body');
        // @ts-ignore
        bodyDOM.classList.remove('showing-modal');
    }
};
__decorate([
    Prop({ type: String, required: true })
], DonationForm.prototype, "currentLadyObjectId", void 0);
__decorate([
    Prop({ type: String, required: true })
], DonationForm.prototype, "saveName", void 0);
__decorate([
    Prop({ type: Number, required: true })
], DonationForm.prototype, "selectedGiftPackageId", void 0);
__decorate([
    Prop({ type: Function, default: function () { } })
], DonationForm.prototype, "donateSuccess", void 0);
__decorate([
    Prop()
], DonationForm.prototype, "accessToken", void 0);
DonationForm = __decorate([
    Component({
        name: 'ActivityDonationForm',
    })
], DonationForm);
export default DonationForm;
