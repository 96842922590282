export var Place;
(function (Place) {
    Place["Game"] = "Jkface\u4E0A\u7684\u8CBC\u6587\u904A\u6232\u6A5F";
    Place["GameBag"] = "Jkface\u4E0A\u7684\u8CBC\u6587\u904A\u6232\u6A5F,\u9EDE\u64CA\u98A8\u683C\u6309\u9215\u6309\u9215\u5C55\u958B\u7684\u80CC\u5305";
    Place["JkfaceGift"] = "Jkface\u79AE\u7269\u8CFC\u8CB7\u4ECB\u9762";
    Place["JkliveGift"] = "Jklive\u79AE\u7269\u8CFC\u8CB7\u4ECB\u9762";
    Place["JkliveAnimation"] = "Jklive\u9001\u51FA\u79AE\u7269\u6642\u64AD\u653E\u7684\u52D5\u756B";
    Place["JkliveHeart"] = "Jkflive\u611B\u5FC3\u6C23\u6CE1\u6309\u9215";
    Place["Notification"] = "\u6536\u79AE\u901A\u77E5";
    Place["Server"] = "\u9700\u8981\u5165\u8CC7\u6599\u5EAB\u8A18\u9304\u7684";
})(Place || (Place = {}));
export var Category;
(function (Category) {
    Category["PrimaryGift"] = "\u5E38\u898F\u79AE\u7269";
    Category["PrimaryGiftEx"] = "\u5E38\u898F\u79AE\u7269(\u984D\u5916): \u901A\u5E38\u662Fjkflive\u5C08\u5C6C\u79AE\u7269, \u6703\u5728\u904A\u6232\u6A5F\u4E0A\u51FA\u73FE\u4F46\u4E0D\u6703\u5728jkface\u79AE\u7269\u4ECB\u9762\u4E2D\u51FA\u73FE";
    Category["FreeGift"] = "\u514D\u8CBB\u79AE\u7269: \u904A\u6232\u6A5F\u958B\u59CB\u5F8C\uFF0C\u4F9D\u7167\u4F3A\u670D\u5668\u6642\u9593\u5B9A\u6642\u7372\u5F97";
    Category["PackageGift"] = "\u79AE\u5305\u79AE\u7269: \u900F\u904E\u8CFC\u8CB7 PackageNormal \u6216 PackageLimited \u7372\u5F97";
    Category["Skin"] = "\u9020\u578B:\u4E3B\u8981\u662F\u4E3B\u8996\u89BA\u9020\u578B, \u4EE5\u524D\u6709\u51FA\u904E\u5E38\u898F\u79AE\u7269\u9020\u578B, \u4F46\u8FD1\u671F\u597D\u50CF\u90FD\u6C92\u6709\u4E86";
    Category["PackageNormal"] = "\u666E\u901A\u79AE\u5305: \u6BCF\u65E5\u9650\u8CFC\u4E00\u6B21";
    Category["PackageLimited"] = "\u9650\u5B9A\u79AE\u5305: \u6BCF\u8CFC\u8CB7 PackageNormal 10\u6B21(fixed)\uFF0C\u6703\u7372\u5F97\u4E00\u6B21\u8CFC\u8CB7\u6B0A";
    Category["Panel"] = "\u904A\u6232\u6A5F\u63A7\u5236\u9762\u677F";
})(Category || (Category = {}));
export class ControlPanelImages {
    constructor() {
        this.nameBadges = {
            normal: '',
            disabled: '',
            special: '',
            limited: '',
        };
        this.freeGift = {
            normal: '',
            disabled: '',
        };
        this.packageGift = {
            normal: '',
            disabled: '',
        };
        this.normalPackage = {
            normal: '',
            disabled: '',
        };
        this.limitedPackage = {
            normal: '',
            disabled: '',
        };
        this.panelImage = '';
        this.bagImage = '';
        this.gifts = {
            1: {
                normal: '',
                disabled: ''
            },
            2: {
                normal: '',
                disabled: ''
            },
            3: {
                normal: '',
                disabled: ''
            },
            4: {
                normal: '',
                disabled: ''
            },
            5: {
                normal: '',
                disabled: ''
            },
        };
    }
}
