import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Bag from '@/components/bag-entry.vue';
import TopRank from '@/components/top-rank-entry.vue';
import DailyPackage from '@/components/daily-package-entry.vue';
import Donation from '@/components/donation-entry.vue';
import Activity from '@/components/activityV3/activityV3.vue';
import NotificationVue from '../notification.vue';
import { FaceService } from '@/api/face-front-api/face';
import { activityApi } from '@/utils/activity';
let ActivityV3Entry = class ActivityV3Entry extends Vue {
    constructor() {
        super(...arguments);
        this.currentLadyObjectId = '';
        this.isReady = false;
    }
    get modalId() {
        return `${this.componentId}${this.currentLadyObjectId}${this.activityId}`;
    }
    mounted() {
        this.init();
        this.$root.$on(activityApi.activityV3EventBusName + this.activityId, (action) => {
            switch (action) {
                case 'login':
                    this.$emit('login');
                    break;
                case 'validate':
                    this.$emit('validate');
                    break;
            }
        });
    }
    beforeDestroy() {
        this.$root.$off(activityApi.activityV3EventBusName + this.activityId);
    }
    async init() {
        this.isReady = false;
        const faceService = new FaceService();
        let faceObjectId = await faceService.getObjectFromUid({ faceUid: this.currentLadyUid });
        this.currentLadyObjectId = faceObjectId;
        const timer = (() => {
            let maxTimes = 30;
            let count = 0;
            return setInterval(() => {
                if (this.isGetAccessToken) {
                    this.showNotification();
                    clearInterval(timer);
                }
                count++;
                if (maxTimes === count)
                    clearInterval(timer);
            }, 1000);
        })();
        this.isReady = true;
    }
    async showNotification() {
        const accessToken = this.accessToken;
        if (accessToken !== '') {
            const unreadNotification = await activityApi.getUnreadNotification(accessToken);
            if (unreadNotification.isAny) {
                this.$modal.show(NotificationVue, {
                    originUnreadNotification: unreadNotification,
                    accessToken: accessToken,
                }, activityApi.notificationConfig);
            }
        }
    }
};
__decorate([
    Prop({ type: String, default: Date.now().toString() })
], ActivityV3Entry.prototype, "componentId", void 0);
__decorate([
    Prop({ type: Number, required: true })
], ActivityV3Entry.prototype, "activityId", void 0);
__decorate([
    Prop({ type: Number, required: true })
], ActivityV3Entry.prototype, "currentLadyUid", void 0);
__decorate([
    Prop()
], ActivityV3Entry.prototype, "accessToken", void 0);
__decorate([
    Prop()
], ActivityV3Entry.prototype, "isGetAccessToken", void 0);
ActivityV3Entry = __decorate([
    Component({
        name: 'ActivityV3Entry',
        components: {
            Activity,
            Bag,
            TopRank,
            DailyPackage,
            Donation,
        },
    })
], ActivityV3Entry);
export default ActivityV3Entry;
