import { __decorate } from "tslib";
import { Vue, Component, Watch } from 'vue-property-decorator';
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.activityList = [
            { name: '閃耀聖誕', id: 1 },
            { name: 'JKF拉霸機', id: 2 },
            { name: '美蛇雙姬慶端午', id: 3 },
            { name: '聖誕愛戀雪花球', id: 5 },
            { name: '女神龍舟隊', id: 13 },
            { name: '情定夢幻月宮', id: 16 },
            { name: '華麗聖誕驚喜盒', id: 19 },
            { name: '春の櫻花女神', id: 22 },
            { name: '情慾溫泉湯屋', id: 26 },
            { name: '聖誕情人夢', id: 31 },
            { name: '春神來撩', id: 34 },
            { name: '仲夏戀人', id: 37 },
            { name: '秋月慾兔', id: 40 },
            { name: '麋鹿女孩', id: 43 }
        ];
        this.defaultAuthTokens = [
            {
                name: '草莓Honey',
                token: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdXRoX21ldGhvZCI6ImF1dGgtYnktcGFzc3dvcmQiLCJleHBpcmUiOjE5NTExOTk0ODcsIm9iamVjdF9pZCI6IjY3MmNkNWQ2LTBjNGMtNDg5MC05YzQ1LTU3NTY3MWMyM2IwMyIsInRva2VuMSI6IjdmMmQ2NTc5LWI0YjUtNDlhOC05YmYxLWEwYmU3YWE0YzU3YyIsInRva2VuMiI6IjMwNmEyNzRkLThjNDctNDE5Ny1iM2I2LWZlMjcxMWUxZmE1MSIsInVpZCI6NDg5OTM2MX0.pM3mMMc5xXWVtbaO6JKdUGJxdWW1iF1K4TubSOLp3bJ8qoAesFPRLzK6-obe7_du15yCdRjIecuhbmCzy3fkQQ',
            },
            {
                name: 'user',
                token: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdXRoX21ldGhvZCI6ImF1dGgtYnktcGFzc3dvcmQiLCJleHBpcmUiOjE5ODM3NTI0NTcsIm9iamVjdF9pZCI6IjMyYzRkN2JkLTliNzgtNDI3NC1iYTJmLWMxYzhkMzU5ZTAxOCIsInRva2VuMSI6IjdmMmQ2NTc5LWI0YjUtNDlhOC05YmYxLWEwYmU3YWE0YzU3YyIsInRva2VuMiI6ImJiMmNkNjU4LTQ1ZjUtNGJhNi05ODk2LWU4N2QyNzExNTQzMyIsInVpZCI6NDM1MzQyMn0.QRlAa7ThyuImtJN2_zH2OOzy1gC6CBRr5qRm1vS-RRApwcfD8RqxjaFUajqWzf4ca2QDHaCgsza_MGeRI4HXcA',
            },
        ];
        this.currentAuthToken = null;
        this.isShow = true;
    }
    get showSidebar() {
        return window.location.href.includes('local') && this.isShow;
    }
    setAuthToken(token) {
        this.currentAuthToken = token;
    }
    clearAuthToken() {
        this.currentAuthToken = null;
    }
    reload() {
        window.location.reload();
    }
    close() {
        this.isShow = false;
    }
    async beforeMount() {
        const gubed = localStorage.getItem('gubed');
        const isProd = process.env.VUE_APP_STAGE === 'prod';
        if (gubed === null) {
            if (isProd) {
                localStorage.setItem('gubed', 'false');
            }
            else {
                localStorage.setItem('gubed', 'true');
            }
        }
    }
    routeChange() {
        this.currentAuthToken = null;
    }
};
__decorate([
    Watch('$route')
], App.prototype, "routeChange", null);
App = __decorate([
    Component({
        components: {},
    })
], App);
export default App;
