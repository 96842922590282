import { MemberServicePromiseClient } from '@lctech-tw/jkface-proto/dist/js/face-front-api/member_grpc_web_pb';
import { GetMemberInfoReq, } from '@lctech-tw/jkface-proto/dist/js/face-front-api/member_pb';
import { enableGrpcWebDevtools } from '@/api/grpc-tool/enable-grpc-web-devtools';
export class MemberService {
    constructor() {
        this.getMeInfo = async (params) => {
            const req = new GetMemberInfoReq();
            req.setAccessToken(params.accessToken);
            const res = await this.memberClient.getMemberInfo(req);
            return res.toObject();
        };
        this.memberClient = new MemberServicePromiseClient(process.env.VUE_APP_FACE_SERVICE_HOST);
        enableGrpcWebDevtools([
            this.memberClient,
        ]);
    }
}
