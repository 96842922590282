import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ControlPanel from '@/components/activityV3/control-panelV3.vue';
import { showTopRank } from '@/utils/modal_api';
import { Player } from '@/game/pixi_v3';
import { ControlPanelImages } from '@/game/info';
let ActivityV3 = class ActivityV3 extends Vue {
    constructor() {
        super(...arguments);
        this.player = undefined;
        this.tips = [];
        this.landingPageUrl = '';
        this.panelImages = new ControlPanelImages();
    }
    get modalId() {
        return `${this.componentId}${this.ladyObjectId}${this.activityId}`;
    }
    accessTokenChanged() {
        this.control('refresh');
    }
    activityIdChanged() {
        this.control('refresh');
    }
    ladyObjectIdChanged() {
        this.control('refresh');
    }
    mounted() {
        this.init();
    }
    init() {
        this.player = new Player({
            el: this.$el,
            activityId: this.activityId,
            ladyObjectId: this.ladyObjectId,
            sendGiftByIndex: this.sendGiftByIndex,
            width: this.$el.clientWidth,
        });
        this.player.start();
        this.landingPageUrl = this.player.landingPageUrl;
        this.panelImages = this.player.controlPanelImages;
    }
    control(key, receivePoint) {
        const refresh = () => {
            if (this.player) {
                this.player.refresh();
            }
        };
        switch (key) {
            case 'free':
                refresh();
                this.player && this.player.showSendGiftEffect(receivePoint);
                break;
            case 'paid':
                refresh();
                this.player && this.player.showSendGiftEffect(receivePoint);
                break;
            case 'gift':
                refresh();
                this.player && this.player.showSendGiftEffect();
                break;
            case 'refresh':
                refresh();
                break;
            default:
                break;
        }
    }
    openTopFans() {
        showTopRank(this, this.modalId);
    }
    sendGiftByIndex(giftIndex) {
        this.$root.$emit('sendGiftByIndex', giftIndex);
    }
};
__decorate([
    Prop()
], ActivityV3.prototype, "accessToken", void 0);
__decorate([
    Prop()
], ActivityV3.prototype, "ladyObjectId", void 0);
__decorate([
    Prop()
], ActivityV3.prototype, "activityId", void 0);
__decorate([
    Prop({ type: String, default: Date.now().toString() })
], ActivityV3.prototype, "componentId", void 0);
__decorate([
    Watch('accessToken')
], ActivityV3.prototype, "accessTokenChanged", null);
__decorate([
    Watch('activityId')
], ActivityV3.prototype, "activityIdChanged", null);
__decorate([
    Watch('ladyObjectId')
], ActivityV3.prototype, "ladyObjectIdChanged", null);
ActivityV3 = __decorate([
    Component({
        name: 'ActivityV3',
        components: {
            ControlPanel,
        },
    })
], ActivityV3);
export default ActivityV3;
