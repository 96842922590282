import { __decorate } from "tslib";
//@ts-nocheck
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import ControlPanel from '@/components/gpg/control-panel.vue';
import TemplateActivity from '@/components/gpg/activity';
import GiftList from '@/components/gpg/giftlist';
import { GetLadyActivity } from '@/api/jkf-pay/activity';
import { showTopRank } from '@/utils/modal_api';
import Noty from 'noty';
let Superbar = class Superbar extends TemplateActivity {
    constructor() {
        super(...arguments);
        this.expNow = 0;
        this.expLimit = 0;
        this.gameUrl = '';
        this.controlbar = true;
        this.maskSetting = {};
    }
    get maskId() {
        return `superbar_mask_${this.activityId}`;
    }
    get isEnded() {
        return new Date() >= new Date('2021/03/08 23:59:59 GMT+0800');
    }
    wChanged(val) {
        if (val) {
            console.log('gameUrl', this.iframe);
            this.$nextTick(() => {
                if (this.iframe) {
                    this.$el.style.width = this.w + 'px';
                    this.$el.style.height = this.h + 'px';
                    this.iframe.style.width = `${this.w}px`;
                    this.iframe.style.height = `${this.w / 9 * 16}px`;
                }
            });
        }
    }
    mounted() {
        this.$el.style.backgroundColor = 'black';
        fetch(`${process.env.VUE_APP_SETTING_HOST}/activity/${this.activityId}/setting.json`, { cache: 'no-store' })
            .then((res) => {
            return res.json();
        })
            .then((setting) => {
            this.maskSetting = setting.mask;
            this.topFans.style.backgroundImage = `url('${process.env.VUE_APP_RESOURCE_HOST}/activity/${this.activityId}/fans_rank.svg')`;
            this.experienceBar.style.backgroundImage = `url('${process.env.VUE_APP_RESOURCE_HOST}/activity/${this.activityId}/Experience_bar.png')`;
            this.infoLink.style.backgroundImage = `url('${process.env.VUE_APP_RESOURCE_HOST}/activity/${this.activityId}/info_link.svg')`;
            this.infoLink.href = setting['landingpage'] || undefined;
            this.$refs.avatar.style.backgroundImage = `url('${this.ladyAvatar}')`;
            this.progressBar();
            this.loadGameUrl();
            window.addEventListener('message', this.receiveMessage);
            this.changeMask();
        });
        const scrollHack = this.$el.querySelector('.superbar__scroll-hack');
        if (scrollHack) {
            scrollHack.style.clipPath = `url(#${this.maskId})`;
            scrollHack.style.webkitClipPath = `url(#${this.maskId})`;
        }
    }
    beforeDestroy() {
        window.removeEventListener('message', this.receiveMessage);
    }
    accessTokenChanged(val, oldVal) {
        if (val && !oldVal) {
            this.loadGameUrl(true);
        }
    }
    changeMask(key = 'default') {
        const list = this.maskSetting;
        if (!list[key]) {
            return;
        }
        const mask = document.querySelector('#' + this.maskId);
        if (mask) {
            const scale = this.w / 500;
            const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            mask.innerHTML = '';
            path.setAttribute('d', list[key]);
            mask.append(path);
            mask.style.transform = `scale(${scale})`;
        }
    }
    progressBar() {
        GetLadyActivity({
            activityId: this.activityId,
            ladyObjectId: this.ladyObjectId,
        }).then((res) => {
            this.expNow = res.getCurrentPoint();
            this.expLimit =
                res.getNextLevelPoint() > 0 ? res.getNextLevelPoint() : 'MAX';
            this.updateProgress();
        });
    }
    updateProgress(val) {
        const bet = val?.split(':')[1].split(',')[0];
        const payout = val?.split(':')[1].split(',')[2];
        if (bet) {
            this.expNow += parseInt(bet);
            if (payout > 0) {
                this.expNow += parseInt(payout);
            }
        }
        const preWidth = this.$refs.progress.style.width || 0;
        this.$refs.progress.style.width =
            this.expLimit === 'MAX' || `${this.expNow / (this.expLimit / 100)}` >= 100
                ? '100%'
                : `${this.expNow / (this.expLimit / 100)}%`;
        console.log('prewidth', preWidth, 'width', this.$refs.progress.style.width);
        this.$refs.progress.animate([
            { width: `${preWidth}` },
            { width: `${this.$refs.progress.style.width}` },
        ], {
            duration: 500,
        });
    }
    loadGameUrl(force) {
        if (!force && this.gameUrl) {
            return;
        }
        if (this.$el) {
            this.$el.style.backgroundImage = `url(${process.env.VUE_APP_RESOURCE_HOST}/activity/${this.activityId}/bg.jpg)`;
        }
        if (this.isEnded) {
            this.gameUrl = 'https://www.mymypic.net/game24/web-mobile/index.html?interframe=true';
            return;
        }
    }
    maskClick() {
        if (this.isEnded) {
            if (!this.isAuthed) {
                this.$root.$emit('login');
            }
            else {
                fetch('https://jkf.hare200.com/api/get-gpg-agent-url?access_token=' +
                    this.accessToken, {
                    // body: formData,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                    },
                    cache: 'no-cache',
                    method: 'POST',
                })
                    .then((response) => {
                    return response.text();
                })
                    .then((url) => {
                    window.open(url, '_blank');
                });
            }
        }
    }
    receiveMessage(ev) {
        if (['https://gamedev.jkf.net', 'https://game.jkforum.net'].includes(ev.origin)) {
            console.log('msg listener', ev);
            this.changeMask(ev.data);
            if (ev.data === 'window-rule' ||
                ev.data === 'window-setting' ||
                ev.data === 'window-slot') {
                this.ishidebar(ev.data);
            }
            else if (ev.data === 'balance-charge') {
                this.buyDiamond();
            }
            else if (ev.data === 'login-required') {
                this.$root.$emit('login');
            }
            else if (ev.data === 'game-terminated') {
                this.loadGameUrl(true);
            }
            else if (ev.data.indexOf('bet-result') >= 0) {
                this.updateProgress(ev.data);
            }
        }
    }
    ishidebar(val) {
        this.controlbar = val === 'window-slot' ? true : false;
        if (this.controlbar) {
            this.$el.style.backgroundImage = '';
        }
    }
    buyDiamond() {
    }
    control() {
        this.changeMask('window-slot');
        this.progressBar();
        if (this.giftList) {
            this.giftList.loadGifts();
        }
    }
    openTopFans() {
        showTopRank(this, this.modalId);
    }
    jkfPayCancel() {
        this.changeMask('window-slot');
    }
    async chargeSuccess() {
        console.log('charge');
        const that = this;
        this.isLoading = true;
        // await this.checkBalance()
        this.$refs.iframe.contentWindow.postMessage('refresh-balance', '*');
        setTimeout(() => {
            new Noty({ text: '鑽石儲值成功' }).show();
            this.isLoading = false;
        }, 1000);
    }
};
__decorate([
    Prop()
], Superbar.prototype, "w", void 0);
__decorate([
    Prop()
], Superbar.prototype, "h", void 0);
__decorate([
    Prop()
], Superbar.prototype, "ladyAvatar", void 0);
__decorate([
    Ref('infoLink')
], Superbar.prototype, "infoLink", void 0);
__decorate([
    Ref('topFans')
], Superbar.prototype, "topFans", void 0);
__decorate([
    Ref('experienceBar')
], Superbar.prototype, "experienceBar", void 0);
__decorate([
    Ref('iframe')
], Superbar.prototype, "iframe", void 0);
__decorate([
    Ref('giftList')
], Superbar.prototype, "giftList", void 0);
__decorate([
    Ref('panel')
], Superbar.prototype, "panel", void 0);
__decorate([
    Watch('w')
], Superbar.prototype, "wChanged", null);
__decorate([
    Watch('accessToken')
], Superbar.prototype, "accessTokenChanged", null);
Superbar = __decorate([
    Component({
        name: 'SuperBar',
        components: {
            ControlPanel,
            GiftList,
        },
    })
], Superbar);
export default Superbar;
