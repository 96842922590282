import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueJSModal from 'vue-js-modal';
import TopRank from '@/components/top-rank.vue';
Vue.use(VueJSModal);
import { ModalEvents } from '@/utils/enum';
let TopRankEntryV3 = class TopRankEntryV3 extends Vue {
    constructor() {
        super(...arguments);
        this.topRankModalName = 'toprankmodal';
        this.isMobile = (function () {
            var deviceWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
            return deviceWidth <= 480 ? true : false;
        })();
        this.desktopModalConfig = {
            height: '75%',
            width: 500,
        };
        this.mobileModalConfig = {
            height: 'auto',
            width: '100%',
            classes: 'mobile-modal',
        };
    }
    mounted() {
        this.$root.$on(ModalEvents.SHOW_TOP_RANK, (componentId) => {
            if (this.isMobile && this.componentId === componentId) {
                this.showTopRank(this.mobileModalConfig);
            }
            else if (this.componentId === componentId) {
                this.showTopRank(this.desktopModalConfig);
            }
        });
    }
    beforeDestroy() {
        this.$root.$off(ModalEvents.SHOW_TOP_RANK);
    }
    showTopRank(config) {
        this.$modal.show(TopRank, {
            name: this.topRankModalName,
            close: this.closeTopRank,
            ladyObjectId: this.ladyObjectId,
            activityId: this.activityId,
        }, config);
    }
    closeTopRank() {
        this.$modal.hide(this.topRankModalName);
    }
};
__decorate([
    Prop({ type: String, required: true })
], TopRankEntryV3.prototype, "componentId", void 0);
__decorate([
    Prop({ type: Number, required: true })
], TopRankEntryV3.prototype, "activityId", void 0);
__decorate([
    Prop({ type: String, required: true })
], TopRankEntryV3.prototype, "ladyObjectId", void 0);
TopRankEntryV3 = __decorate([
    Component({
        name: 'ActivityTopRankEntryV3',
    })
], TopRankEntryV3);
export default TopRankEntryV3;
