import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Bag from '@/components/bag-entry.vue';
import TopRank from '@/components/top-rank-entry.vue';
import DailyPackage from '@/components/daily-package-entry.vue';
import Donation from '@/components/donation-entry.vue';
import NotificationVue from '../notification.vue';
import SuperBar from '@/components/gpg/superbar.vue';
import { DiamondService } from '@/api/jkf-pay/diamond';
import { GetNotification, EDUCATIONDOLLS, GetInfoByID } from '@/api/jkf-pay/activity';
import { ReadObjectByUid } from '@/api/pan/member';
let ActivityEntryV2 = class ActivityEntryV2 extends Vue {
    constructor() {
        super(...arguments);
        this.currentLadyObjectId = '';
        this.currentLadyAvatar = '';
        this.isValidateMobile = false;
        this.isV2 = false;
    }
    get modalId() {
        return `${this.componentId}${this.currentLadyObjectId}${this.activityId}`;
    }
    get ActivityProps() {
        let props = {
            accessToken: this.accessToken,
            activityId: this.activityId,
            ladyObjectId: this.currentLadyObjectId,
            ladyAvatar: this.currentLadyAvatar,
            componentId: this.componentId,
            w: this.w,
            h: this.h,
        };
        return props;
    }
    get ActivityComponent() {
        if (this.activityType === 'gpg') {
            return 'SuperBar';
        }
        return 'SuperBar';
    }
    mounted() {
        this.init();
    }
    async init() {
        this.currentLadyObjectId = await this.getCurrentLadyObjectId();
        this.currentLadyAvatar = await this.getCurrentLadyAvatar();
        this.isV2 = await GetInfoByID(this.activityId).then((res) => res.getMainType() === EDUCATIONDOLLS);
        this.showNotification();
    }
    async getCurrentLadyObjectId() {
        const lady = await ReadObjectByUid(this.currentLadyUid);
        return new Promise((resolve) => {
            resolve(lady.getObjectId());
        });
    }
    async getCurrentLadyAvatar() {
        const lady = await ReadObjectByUid(this.currentLadyUid);
        return new Promise((resolve) => {
            resolve(lady.getAvatar());
        });
    }
    async checkIsValidateMobile() {
        try {
            const diamondService = new DiamondService();
            await diamondService.GetDiamondAmount({ accessToken: this.accessToken });
            return new Promise((resolve) => {
                resolve(true);
            });
        }
        catch (error) {
            return new Promise((resolve) => {
                resolve(false);
            });
        }
    }
    async showNotification() {
        const accessToken = this.accessToken;
        const unreadNotification = await GetNotification(accessToken).then((res) => {
            return {
                giftName: res.getTitle(),
                giftAmount: res.getAmount(),
                giftAnimation: res.getImgUrl(),
                message: res.getMessage(),
                unreadCount: res.getUnreadCount(),
            };
        });
        // const unreadNotification: UnreadNotification = {
        //   giftAmount: 1,
        //   giftAnimation: "https://local.public.hare200.com/donate/gift/success_candyskin.png",
        //   giftName: "彩虹拐杖糖",
        //   message: "請至 <b>贈送造型</b> 查看收到的造型！",
        //   unreadCount: 3
        // }
        if (unreadNotification.unreadCount > 0) {
            this.$modal.show(NotificationVue, {
                originUnreadNotification: unreadNotification,
                accessToken: this.accessToken,
            }, {
                name: 'notification',
                height: 'auto',
                width: '100%',
                maxWidth: 556,
                scrollable: true,
                classes: 'notification-entry',
            });
        }
    }
};
__decorate([
    Prop({ type: String, default: Date.now().toString() })
], ActivityEntryV2.prototype, "componentId", void 0);
__decorate([
    Prop({ type: String, required: true })
], ActivityEntryV2.prototype, "activityType", void 0);
__decorate([
    Prop({ type: Number, required: true })
], ActivityEntryV2.prototype, "activityId", void 0);
__decorate([
    Prop({ type: Number, required: true })
], ActivityEntryV2.prototype, "currentLadyUid", void 0);
__decorate([
    Prop()
], ActivityEntryV2.prototype, "w", void 0);
__decorate([
    Prop()
], ActivityEntryV2.prototype, "h", void 0);
__decorate([
    Prop()
], ActivityEntryV2.prototype, "accessToken", void 0);
ActivityEntryV2 = __decorate([
    Component({
        name: 'ActivityEntryV2',
        components: {
            SuperBar,
            Bag,
            TopRank,
            DailyPackage,
            Donation,
        },
    })
], ActivityEntryV2);
export default ActivityEntryV2;
