import { Spine } from 'pixi-spine';
import { GameParts } from './game';
import { GameAlphaTemplate } from './game_alpha_template';
var XmasParts;
(function (XmasParts) {
    XmasParts["snowfall"] = "snowfall";
})(XmasParts || (XmasParts = {}));
const SCORE_TEXT_STYLE = {
    fill: '#ffffff',
    fontSize: 20,
    fontWeight: 'bold',
    stroke: '#1d6d05',
    strokeThickness: 6,
};
const GIFT_TEXT_STYLE = {
    align: 'center',
    fill: '#450429',
    fontSize: 21,
    fontWeight: '400',
};
const GIFT_PARTS = [
    {
        key: GameParts.gift1,
        position: { x: 75, y: 530 },
        autoPosition: 'fix',
        giftIndex: 0,
        defaultSkinName: 'part1',
    },
    {
        key: GameParts.gift2,
        position: { x: 565, y: 147 },
        autoPosition: 'fix',
        giftIndex: 1,
        defaultSkinName: 'part2',
    },
    {
        key: GameParts.gift3,
        position: { x: 155, y: 197 },
        autoPosition: 'fix',
        giftIndex: 2,
        defaultSkinName: 'part3',
    },
    {
        key: GameParts.gift4,
        position: { x: 645, y: 517 },
        autoPosition: 'fix',
        giftIndex: 3,
        defaultSkinName: 'part4',
    },
];
export class Xmas2020 extends GameAlphaTemplate {
    constructor() {
        super(...arguments);
        this.mainDefaultSkinName = 'tree_level_01';
        this.addSnowfall = (resources) => {
            const app = this.app;
            const spineData = resources[XmasParts.snowfall].spineData;
            const snowfall = new Spine(spineData);
            snowfall.x = 0;
            snowfall.y = app.screen.height;
            snowfall.scale.set(this.scale);
            const animationName = 'snowfall';
            snowfall.state.hasAnimation(animationName) && snowfall.state.setAnimation(0, animationName, true);
            app.stage.addChild(snowfall);
            return snowfall;
        };
        this.addBgSpine = (resources) => {
            const app = this.app;
            const spineData = resources[GameParts.bg].spineData;
            const bg = new Spine(spineData);
            bg.scale.set(this.scale);
            app.stage.addChild(bg);
            return bg;
        };
    }
    async init() {
        const app = this.app;
        await new Promise((resolve, reject) => {
            app.loader.add(GameParts.main, 'https://public.hare200.com/activity/1/spine/tree_level.json')
                .add(GameParts.bg, 'https://public.hare200.com/activity/1/spine/background_effect.json')
                .add(XmasParts.snowfall, 'https://public.hare200.com/activity/1/spine/snowfall.json')
                .add(GameParts.scoreBar, 'https://public.hare200.com/activity/1/spine/experience_bar.json')
                .add(GameParts.gift1, 'https://public.hare200.com/activity/1/spine/part1.json')
                .add(GameParts.gift2, 'https://public.hare200.com/activity/1/spine/part2.json')
                .add(GameParts.gift3, 'https://public.hare200.com/activity/1/spine/part3.json')
                .add(GameParts.gift4, 'https://public.hare200.com/activity/1/spine/part4.json');
            app.loader.load((loader, resources) => {
                this.addBgSpine(resources);
                this.addSnowfall(resources);
                this.main = this.addMain(GameParts.main, resources, this.mainDefaultSkinName);
                this.main.x = 0;
                this.score.bar = this.addScoreBar(resources);
                this.score.bar.position.x = 0;
                this.score.text = this.addScoreText({ x: 390, y: 757 }, SCORE_TEXT_STYLE);
                this.addGifts(resources, GIFT_PARTS);
                this.addGiftNumbers(GIFT_PARTS, { x: 0, y: 67 }, GIFT_TEXT_STYLE);
                // 使zIndex 生效
                app.stage.sortChildren();
                resolve(true);
            });
        });
        await this.fetchActivityDetail();
        return true;
    }
}
