import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Noty from 'noty';
import { EquipItemV2, GetLadyActivityV2, SendItemV2, GetMyBagV2, RedeemCode } from '@/api/jkf-pay/activity';
Noty.overrideDefaults({
    layout: 'bottomCenter',
    progressBar: false,
    timeout: 6000,
    theme: 'jkforum',
});
var Job;
(function (Job) {
    Job[Job["DEFAULT"] = 0] = "DEFAULT";
    Job[Job["DONATE"] = 1] = "DONATE";
    Job[Job["APPLY"] = 2] = "APPLY";
})(Job || (Job = {}));
let BagV2 = class BagV2 extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.isCheckingOutSerial = false;
        this.currentTab = 0;
        this.belongings = [];
        this.equipments = [];
        this.dictionaryItems = [];
        this.serialInput = '';
        this.showUseables = false;
        this.selectedItemId = -1;
        this.isShowTargetSelector = false;
        this.jobBuffer = Job.DEFAULT;
        this.targets = [];
        this.targetHumanNames = {
            white: '白白',
            green: '小青',
        };
    }
    get imgHost() {
        return process.env.VUE_APP_RESOURCE_HOST;
    }
    mounted() {
        this.init();
    }
    async init() {
        this.isLoading = true;
        this.currentTab = 0;
        const mybag = await this.getMyBag();
        this.targets = await GetLadyActivityV2({
            activityId: this.activityId,
            ladyObjectId: this.ladyObjectId,
        }).then((res) => {
            return res.getTargetsList().map((e) => {
                return { id: e.getTargetId(), name: e.getTargetName() };
            });
        });
        this.belongings = mybag.belongings;
        this.equipments = mybag.equipments;
        this.dictionaryItems = mybag.dictionaryItems;
        this.isLoading = false;
    }
    async getMyBag() {
        const accessToken = this.accessToken;
        this.showUseables = true;
        const myBag = await GetMyBagV2({
            accessToken: accessToken,
            activityId: this.activityId,
        }).then((res) => {
            return {
                belongings: res.getMyItemsList().map((e) => {
                    return {
                        id: e.getItemId(),
                        icon: e.getImgUrl(),
                        amount: e.getAmount(),
                        // new: target mode
                        name: e.getName(),
                        targetMode: e.getTargetMode(),
                    };
                }),
                equipments: res.getEquipableItemsList().map((e) => {
                    return {
                        id: e.getItemId(),
                        icon: e.getImgUrl(),
                        amount: e.getAmount(),
                        defaultTargetId: e.getTargetId(),
                        name: e.getTargetTitle(),
                    };
                }),
                dictionaryItems: res.getAchievementItemsList().map((e) => {
                    return {
                        icon: e.getImgUrl(),
                        name: e.getName(),
                        isCompleted: e.getCompleted(),
                    };
                }),
            };
        });
        return new Promise((resolve) => {
            resolve(myBag);
        });
    }
    donate(itemId) {
        this.selectedItemId = itemId;
        this.jobBuffer = Job.DONATE;
        this.toggleTargetSelector();
    }
    async doDonate(targetId) {
        try {
            const accessToken = this.accessToken;
            const res = await SendItemV2({
                accessToken: accessToken,
                ladyObjectId: this.ladyObjectId,
                amount: 1,
                itemId: this.selectedItemId,
                targetId: targetId,
            });
            const remain = res.getAmount();
            new Noty({ text: '贈送道具成功' }).show();
            let targetItem = this.belongings.find((e) => {
                return e.id === this.selectedItemId;
            }) || { id: 0, icon: '', amount: 0 };
            targetItem.amount = remain;
            this.donateSuccess(res.getReceivePoint());
        }
        catch (error) {
            new Noty({ text: '贈送道具失敗' }).show();
        }
    }
    async apply(item) {
        try {
            const accessToken = this.accessToken;
            await EquipItemV2({
                accessToken: accessToken,
                itemId: item.id,
                targetId: item.defaultTargetId,
            });
            new Noty({ text: '套用道具成功' }).show();
            this.applySuccess();
        }
        catch (error) {
            new Noty({ text: '套用道具失敗' }).show();
        }
    }
    toggleTargetSelector(bool) {
        if (bool === undefined) {
            this.isShowTargetSelector = !this.isShowTargetSelector;
        }
        else if (bool === true) {
            this.isShowTargetSelector = true;
        }
        else if (bool === false) {
            this.isShowTargetSelector = false;
        }
    }
    async chooseTarget(targetHumanName) {
        try {
            switch (this.jobBuffer) {
                case Job.DONATE:
                    this.doDonate(this.targets.find((e) => e.name === targetHumanName)?.id || 0);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            new Noty({ text: '出錯啦' }).show();
        }
    }
    async checkoutSerial() {
        const that = this;
        if (this.serialInput.match(/^\s*$/)) {
            // 空字串或空白內容
            new Noty({ text: '請輸入序號' }).show();
        }
        else {
            // 有內容
            try {
                this.isCheckingOutSerial = true;
                const accessToken = this.accessToken;
                await RedeemCode(accessToken, this.serialInput);
                this.serialInput = '';
                this.init();
                new Noty({ text: '序號兌換成功' }).show();
            }
            catch (error) {
                console.log('check serial: ', error);
                this.serialInput = '';
                const err = error;
                new Noty({ text: `序號兌換失敗 ${err.message}` }).show();
            }
            this.isCheckingOutSerial = false;
        }
    }
    close() {
        this.$emit('close');
    }
    setCurrentTab(tab) {
        this.currentTab = tab;
    }
    gotoLandingPage() {
        const el = document.querySelector(`a[activity-id="${this.activityId}"]`);
        if (el) {
            window.open(el.href);
        }
    }
};
__decorate([
    Prop({ type: Number, required: true })
], BagV2.prototype, "activityId", void 0);
__decorate([
    Prop({ type: String, required: true })
], BagV2.prototype, "ladyObjectId", void 0);
__decorate([
    Prop({ type: Function, default: function () { } })
], BagV2.prototype, "donateSuccess", void 0);
__decorate([
    Prop({ type: Function, default: function () { } })
], BagV2.prototype, "applySuccess", void 0);
__decorate([
    Prop()
], BagV2.prototype, "accessToken", void 0);
BagV2 = __decorate([
    Component({
        name: 'ActivityBagV2',
    })
], BagV2);
export default BagV2;
