import { DiamondServicePromiseClient } from '@lctech-tw/jkf-pay-proto/compiled/donate/diamond_grpc_web_pb';
import { GetDiamondAmountReq, CheckFreeDiamondReq, GetDiamondPackagesReq, DiamondPackageType } from '@lctech-tw/jkf-pay-proto/compiled/donate/diamond_pb';
import { enableGrpcWebDevtools } from '@/api/grpc-tool/enable-grpc-web-devtools';
export const DPT_ALL = DiamondPackageType.DPT_ALL;
export const DPT_DAILY = DiamondPackageType.DPT_DAILY;
export const DPT_MONTHLY = DiamondPackageType.DPT_MONTHLY;
export class DiamondService {
    constructor() {
        this.GetDiamondAmount = async (params) => {
            const req = new GetDiamondAmountReq();
            req.setAccessToken(params.accessToken);
            const res = await this.diamondClient.getDiamondAmount(req);
            return res.toObject().diamondAmount;
        };
        this.CheckFreeDiamond = async (params) => {
            const req = new CheckFreeDiamondReq();
            req.setAccessToken(params.accessToken);
            req.setGetItRightNow(params.getItRightNow ? params.getItRightNow : false);
            const res = await this.diamondClient.checkFreeDiamond(req);
            return res.toObject().cooldownSeconds;
        };
        this.GetDiamondPackages = async (params) => {
            const req = new GetDiamondPackagesReq();
            req.setAccessToken(params.accessToken);
            req.setFilterType(DiamondPackageType.DPT_ALL);
            const res = await this.diamondClient.getDiamondPackages(req);
            return res.toObject().packagesList;
        };
        this.diamondClient = new DiamondServicePromiseClient(process.env.VUE_APP_DONATE_API_HOST);
        enableGrpcWebDevtools([
            this.diamondClient,
        ]);
    }
}
