import * as PIXI from 'pixi.js';
import { Spine } from 'pixi-spine';
import { Progress, addCenterAlignedTextContainer, GameParts } from './game';
import { GetLadyActivity } from '@/api/jkf-pay/activity';
import { GameAlphaTemplate } from './game_alpha_template';
const SCORE_TEXT_STYLE = {
    fill: '#ffffff',
    fontSize: 20,
    fontWeight: 'bold',
    stroke: '#18617c',
    strokeThickness: 6,
};
const GIFT_TEXT_STYLE = {
    align: 'center',
    fill: '#fff0b4',
    fontSize: 21,
    fontWeight: '400',
};
const GIFT_PARTS = [
    {
        key: GameParts.gift1,
        position: { x: 90, y: 80 },
        giftIndex: 0,
        defaultSkinName: 'normal',
        isInteractive: true,
    },
    {
        key: GameParts.gift2,
        position: { x: 90, y: 629 },
        giftIndex: 1,
        defaultSkinName: 'normal',
        isInteractive: true,
    },
    {
        key: GameParts.gift3,
        position: { x: 630, y: 80 },
        giftIndex: 2,
        defaultSkinName: 'normal',
        isInteractive: true,
    },
    {
        key: GameParts.gift4,
        position: { x: 630, y: 385 },
        giftIndex: 3,
        defaultSkinName: 'normal',
        isInteractive: true,
    },
    {
        key: GameParts.gift5,
        position: { x: 630, y: 629 },
        giftIndex: 4,
        defaultSkinName: 'normal'
    },
];
export class DragonBoat extends GameAlphaTemplate {
    constructor() {
        super(...arguments);
        this.boardText = { from: undefined, to: undefined };
        this.addEffect = (resources) => {
            const app = this.app;
            const spineData = resources[GameParts.effect].spineData;
            const effect = new Spine(spineData);
            effect.x = 0;
            effect.y = app.screen.height;
            effect.scale.set(this.scale);
            effect.alpha = 0;
            app.stage.addChild(effect);
            return effect;
        };
        this.addBoardText = () => {
            const app = this.app;
            const scale = this.scale;
            const positions = [
                { x: 612, y: 237 },
                { x: 120, y: 237 }
            ];
            const texts = positions.map((position, index) => {
                const text = new PIXI.Text('', new PIXI.TextStyle({
                    align: 'center',
                    fill: '#ffffff',
                    fontSize: 21,
                    fontWeight: '400',
                }));
                const textContainer = addCenterAlignedTextContainer(position.x, position.y, 2, scale, app, text);
                if (index === 0) {
                    this.boardText.from = text;
                }
                else if (index === 1) {
                    this.boardText.to = text;
                }
                return text;
            });
            app.start();
        };
    }
    async init() {
        const app = this.app;
        await new Promise((resolve, reject) => {
            app.loader.add(GameParts.main, 'https://public.hare200.com/activity/13/spine/2022_dragon_boat.json')
                .add(GameParts.effect, 'https://public.hare200.com/activity/13/spine/effect_bulb.json')
                .add(GameParts.scoreBar, 'https://public.hare200.com/activity/13/spine/experience_bar.json')
                .add(GameParts.gift1, 'https://public.hare200.com/activity/13/spine/btns/part1.json')
                .add(GameParts.gift2, 'https://public.hare200.com/activity/13/spine/btns/part2.json')
                .add(GameParts.gift3, 'https://public.hare200.com/activity/13/spine/btns/part3.json')
                .add(GameParts.gift4, 'https://public.hare200.com/activity/13/spine/btns/part4.json')
                .add(GameParts.gift5, 'https://public.hare200.com/activity/13/spine/btns/part5.json')
                .load((loader, resources) => {
                this.main = this.addMain(GameParts.main, resources, this.mainDefaultSkinName);
                this.sendGiftEffect = this.addEffect(resources);
                this.score.bar = this.addScoreBar(resources);
                this.score.text = this.addScoreText({ x: 390, y: 757 }, SCORE_TEXT_STYLE);
                this.addGifts(resources, GIFT_PARTS);
                this.addGiftNumbers(GIFT_PARTS, { x: 0, y: 67 }, GIFT_TEXT_STYLE);
                this.addBoardText();
                // 使zIndex 生效
                app.stage.sortChildren();
                resolve(true);
            });
        });
        await this.fetchActivityDetailCustom();
        await this.fetchActivityDetail();
        return true;
    }
    async fetchActivityDetailCustom() {
        const res = await GetLadyActivity({ activityId: this.activityId, ladyObjectId: this.ladyObjectId });
        const currentProgress = this.progress;
        const nextProgress = new Progress({
            currentExp: res.getCurrentPoint(),
            currentLevel: res.getCurrentLevel(),
            limitExp: res.getNextLevelPoint(),
            limitLevel: res.getMaxLevel(),
        });
        const mainAnimationName = this.mainAnimationPrefix + res.getCurrentLevel();
        if (this.main?.state.hasAnimation(mainAnimationName)) {
            if (currentProgress.currentLevel != nextProgress.currentLevel) {
                this.main?.state.setAnimation(0, mainAnimationName, true);
            }
        }
        if (this.boardText.from && this.boardText.to) {
            if (nextProgress.currentExp === 0) {
                this.boardText.from.text = 'START';
                this.boardText.to.text = 'START';
            }
            else if (nextProgress.currentExp <= 100) {
                this.boardText.from.text = 'START';
                this.boardText.to.text = '100M';
            }
            else {
                this.boardText.from.text = `${nextProgress.currentExp - 100}M`;
                this.boardText.to.text = `${nextProgress.currentExp}M`;
            }
        }
    }
    async refresh() {
        await this.fetchActivityDetailCustom();
        await this.fetchActivityDetail();
    }
}
