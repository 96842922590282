import * as PIXI from 'pixi.js';
import { Spine } from 'pixi-spine';
import { Progress, GameParts, addCenterAlignedTextContainer } from './game';
import { GetLadyActivity } from '@/api/jkf-pay/activity';
import { GameAlphaTemplate } from './game_alpha_template';
const SEND_GIFT_EFFECT_TEXT_STYLE = {
    fill: '#ffffff',
    fontSize: 36,
    fontWeight: '300',
    stroke: '#cd6500',
    strokeThickness: 3,
};
const SCORE_TEXT_STYLE = {
    fill: '#ffffff',
    fontSize: 20,
    fontWeight: 'bold',
    stroke: '#cd6500',
    strokeThickness: 2,
};
const GIFT_TEXT_STYLE = {
    align: 'center',
    fill: '#fff0b4',
    fontSize: 21,
    fontWeight: '400',
};
const GIFT_PARTS = [
    {
        key: GameParts.gift1,
        position: { x: 82, y: 92 },
        giftIndex: 0,
        defaultSkinName: 'disable',
        isInteractive: true,
        autoPosition: 'fix2',
    },
    {
        key: GameParts.gift2,
        position: { x: 82, y: 632 },
        giftIndex: 1,
        defaultSkinName: 'disable',
        isInteractive: true,
        autoPosition: 'fix2',
    },
    {
        key: GameParts.gift3,
        position: { x: 640, y: 92 },
        giftIndex: 2,
        defaultSkinName: 'disable',
        isInteractive: true,
        autoPosition: 'fix2',
    },
    {
        key: GameParts.gift4,
        position: { x: 640, y: 363 },
        giftIndex: 3,
        defaultSkinName: 'disable',
        isInteractive: true,
        autoPosition: 'fix2',
    },
    {
        key: GameParts.gift5,
        position: { x: 640, y: 633 },
        giftIndex: 4,
        defaultSkinName: 'disable',
        autoPosition: 'fix2',
    },
];
export class Xmas2022 extends GameAlphaTemplate {
    constructor() {
        super(...arguments);
        this.mainDefaultSkinName = 'XmasTreeLV_01';
        this.mainAnimationName = 'animation';
        this.sendGiftEffectText = undefined;
        this.sendGiftEffectAnimationName = 'sendgift_effect';
        this.levelUpEffect = undefined;
        this.levelUpEffectAnimationName = 'level_up';
        this.auras = [];
        this.addSendGiftEffectText = (position, style, point) => {
            const app = this.app;
            const scale = this.scale;
            const text = point ? `+${point}` : '';
            const expText = new PIXI.Text(text, new PIXI.TextStyle(style));
            const textEffect = () => {
                expText.y -= (120) * 0.1;
                expText.alpha -= (1) * 0.25;
            };
            setTimeout(() => {
                app.ticker.add(textEffect);
            }, 300);
            setTimeout(() => {
                app.ticker.remove(textEffect);
            }, 1000);
            const textContainer = addCenterAlignedTextContainer(position.x, position.y, 2, scale, app, expText);
            return expText;
        };
        this.addEffect = (resources) => {
            const app = this.app;
            const spineData = resources[GameParts.effect].spineData;
            const effect = new Spine(spineData);
            effect.x = app.screen.width / 2;
            effect.y = app.screen.height;
            effect.scale.set(this.scale);
            effect.alpha = 0;
            app.stage.addChild(effect);
            return effect;
        };
        this.addLevelUpEffect = (part, resources) => {
            const app = this.app;
            const spineData = resources[GameParts.levelUpEffect].spineData;
            const levelUpEffect = new Spine(spineData);
            levelUpEffect.position.set(app.screen.width / 2, app.screen.height);
            levelUpEffect.scale.set(this.scale);
            levelUpEffect.alpha = 0;
            app.stage.addChild(levelUpEffect);
            app.start();
            return levelUpEffect;
        };
    }
    async init() {
        const app = this.app;
        await new Promise((resolve, reject) => {
            app.loader
                .add(GameParts.main, 'https://public.jkface.net/supports/19/spine/christmasgifttree.json')
                .add(GameParts.effect, 'https://public.jkface.net/supports/19/spine/sendgift_effect.json')
                .add(GameParts.scoreBar, 'https://public.jkface.net/supports/19/spine/experience_bar.json')
                .add(GameParts.gift1, 'https://public.jkface.net/supports/19/spine/part1.json')
                .add(GameParts.gift2, 'https://public.jkface.net/supports/19/spine/part2.json')
                .add(GameParts.gift3, 'https://public.jkface.net/supports/19/spine/part3.json')
                .add(GameParts.gift4, 'https://public.jkface.net/supports/19/spine/part4.json')
                .add(GameParts.gift5, 'https://public.jkface.net/supports/19/spine/part5.json')
                .add(GameParts.levelUpEffect, 'https://public.jkface.net/supports/19/spine/level_up.json')
                .load((loader, resources) => {
                this.main = this.addMain(GameParts.main, resources, this.mainDefaultSkinName);
                if (this.main?.state.hasAnimation(this.mainAnimationName))
                    this.main?.state.setAnimation(0, this.mainAnimationName, true);
                this.sendGiftEffect = this.addEffect(resources);
                this.score.bar = this.addScoreBar(resources);
                this.score.text = this.addScoreText({ x: 390, y: 756 }, SCORE_TEXT_STYLE);
                this.addGifts(resources, GIFT_PARTS);
                this.addGiftNumbers(GIFT_PARTS, { x: 0, y: 50 }, GIFT_TEXT_STYLE);
                this.levelUpEffect = this.addLevelUpEffect(GameParts.main, resources);
                // 使zIndex 生效
                app.stage.sortChildren();
                resolve(true);
            });
        });
        await this.fetchActivityDetailCustom();
        await this.fetchActivityDetail();
        return true;
    }
    async fetchActivityDetailCustom() {
        const res = await GetLadyActivity({ activityId: this.activityId, ladyObjectId: this.ladyObjectId });
        const currentProgress = this.progress;
        const nextProgress = new Progress({
            currentExp: res.getCurrentPoint(),
            currentLevel: res.getCurrentLevel(),
            limitExp: res.getNextLevelPoint(),
            limitLevel: res.getMaxLevel(),
        });
        if (nextProgress.currentLevel !== currentProgress.currentLevel) {
            this.auras.forEach(item => {
                item.alpha = 1;
                const animationName = 'bubble_animation_LV' + res.getCurrentLevel();
                item.state.hasAnimation(animationName) && item.state.setAnimation(0, animationName, true);
            });
        }
        if (this.progress.currentLevel !== 0 && this.progress.currentLevel !== nextProgress.currentLevel) {
            this.showLevelUpEffect();
        }
    }
    showSendGiftEffect(point) {
        if (point === undefined)
            this.sendGiftEffectText = this.addSendGiftEffectText({ x: 360, y: 705 }, SEND_GIFT_EFFECT_TEXT_STYLE);
        else
            this.sendGiftEffectText = this.addSendGiftEffectText({ x: 360, y: 705 }, SEND_GIFT_EFFECT_TEXT_STYLE, point);
        if (this.sendGiftEffect && this.sendGiftEffect.state.hasAnimation(this.sendGiftEffectAnimationName)) {
            this.sendGiftEffect.alpha = 1;
            this.sendGiftEffect.state.setAnimation(0, this.sendGiftEffectAnimationName, false);
        }
    }
    showLevelUpEffect() {
        if (this.levelUpEffect && this.levelUpEffect.state.hasAnimation(this.levelUpEffectAnimationName)) {
            this.levelUpEffect.alpha = 1;
            this.levelUpEffect.state.setAnimation(0, this.levelUpEffectAnimationName, false);
        }
    }
    async refresh() {
        await this.fetchActivityDetailCustom();
        await this.fetchActivityDetail();
    }
}
