function throttle(func, limit) {
    let inThrottle;
    return function () {
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            inThrottle = true;
            func.apply(context, args);
            setTimeout(() => (inThrottle = false), limit);
        }
    };
}
export function Throttle(delay = 300) {
    return (target, prop) => {
        return {
            configurable: true,
            enumerable: false,
            value: throttle(target[prop], delay)
        };
    };
}
