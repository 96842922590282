import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Cookies from 'js-cookie';
import Noty from 'noty';
import { GetMyBag, SendItem, EquipItem, RedeemCode } from '@/api/jkf-pay/activity';
import { MemberService } from '@/api/face-front-api/member';
Noty.overrideDefaults({
    layout: 'bottomCenter',
    progressBar: false,
    timeout: 6000,
    theme: 'jkforum',
});
let Bag = class Bag extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.isCheckingOutSerial = false;
        this.currentTab = 0;
        this.belongings = [];
        this.useables = [];
        this.dictionaryItems = [];
        this.serialInput = '';
        this.showUseables = false;
    }
    mounted() {
        this.init();
    }
    async init() {
        this.isLoading = true;
        this.currentTab = 0;
        const mybag = await this.getMyBag();
        this.belongings = mybag.belongings;
        this.useables = mybag.useables;
        this.dictionaryItems = mybag.dictionaryItems;
        this.isLoading = false;
    }
    async getMyBag() {
        const accessToken = this.accessToken;
        const memberService = new MemberService();
        const me = await memberService.getMeInfo({ 'accessToken': accessToken });
        this.showUseables = me.userType === 1;
        const myBag = await GetMyBag({
            accessToken: accessToken,
            activityId: this.activityId,
        }).then((res) => {
            return {
                belongings: res.getMyItemsList().map((e) => {
                    return {
                        id: e.getItemId(),
                        icon: e.getImgUrl(),
                        amount: e.getAmount(),
                    };
                }),
                useables: res.getEquipableItemsList().map((e) => {
                    return {
                        id: e.getItemId(),
                        icon: e.getImgUrl(),
                        amount: e.getAmount(),
                    };
                }),
                dictionaryItems: res.getAchievementItemsList().map((e) => {
                    return {
                        icon: e.getImgUrl(),
                        name: e.getName(),
                    };
                }),
            };
        });
        return new Promise((resolve) => {
            resolve(myBag);
        });
    }
    async donate(itemId) {
        try {
            const accessToken = this.accessToken;
            const remain = await SendItem({
                accessToken: accessToken,
                itemId: itemId,
                ladyObjectId: this.ladyObjectId,
                amount: 1,
                ga4ClientId: Cookies.get('_ga') ?? ''
            });
            new Noty({ text: '贈送道具成功' }).show();
            let targetItem = this.belongings.find((e) => {
                return e.id === itemId;
            }) || { id: 0, icon: '', amount: 0 };
            targetItem.amount = remain;
            this.donateSuccess();
        }
        catch (error) {
            new Noty({ text: '贈送道具失敗' }).show();
        }
    }
    async apply(itemId) {
        try {
            const accessToken = this.accessToken;
            await EquipItem({ accessToken: accessToken, itemId: itemId });
            new Noty({ text: '套用道具成功' }).show();
            this.applySuccess();
        }
        catch (error) {
            new Noty({ text: '套用道具失敗' }).show();
        }
    }
    async checkoutSerial() {
        const that = this;
        if (this.serialInput.match(/^\s*$/)) {
            // 空字串或空白內容
            new Noty({ text: '請輸入序號' }).show();
        }
        else {
            // 有內容
            try {
                this.isCheckingOutSerial = true;
                const accessToken = this.accessToken;
                await RedeemCode(accessToken, this.serialInput);
                this.serialInput = '';
                this.init();
                new Noty({ text: '序號兌換成功' }).show();
            }
            catch (error) {
                console.log('check serial: ', error);
                this.serialInput = '';
                const err = error;
                new Noty({ text: `序號兌換失敗 ${err.message}` }).show();
            }
            this.isCheckingOutSerial = false;
        }
    }
    close() {
        this.$emit('close');
    }
    setCurrentTab(tab) {
        this.currentTab = tab;
    }
    gotoLandingPage() {
        const el = document.querySelector(`a[activity-id="${this.activityId}"]`);
        if (el) {
            window.open(el.href);
        }
    }
};
__decorate([
    Prop({ type: Number, required: true })
], Bag.prototype, "activityId", void 0);
__decorate([
    Prop({ type: String, required: true })
], Bag.prototype, "ladyObjectId", void 0);
__decorate([
    Prop({ type: Function, default: function () { } })
], Bag.prototype, "donateSuccess", void 0);
__decorate([
    Prop({ type: Function, default: function () { } })
], Bag.prototype, "applySuccess", void 0);
__decorate([
    Prop()
], Bag.prototype, "accessToken", void 0);
Bag = __decorate([
    Component({
        name: 'ActivityBag',
    })
], Bag);
export default Bag;
