import { __decorate } from "tslib";
const toHHMMSS = (secs) => {
    var hours = Math.floor(secs / 3600);
    var minutes = Math.floor(secs / 60) % 60;
    var seconds = secs % 60;
    return [hours, minutes, seconds]
        .map((v) => (v < 10 ? '0' + v : v))
        .filter((v, i) => v !== '00' || i > 0)
        .join(':');
};
import { Component } from 'vue-property-decorator';
import ControlButton from '@/components/gpg/control-button.vue';
import { DiamondService, DPT_DAILY, DPT_MONTHLY } from '@/api/jkf-pay/diamond';
import { GetInfoByID, } from '@/api/jkf-pay/activity';
import TemplateActivity from '@/components/gpg/activity';
import { Throttle } from '@/utils/decorator';
import { showDonation } from '@/utils/modal_api';
const secondsUntilEndOfDate = () => {
    var d = new Date();
    var h = d.getHours();
    var m = d.getMinutes();
    var s = d.getSeconds();
    return (24 * 60 * 60) - (h * 60 * 60) - (m * 60) - s;
};
let GpgControlPanel = class GpgControlPanel extends TemplateActivity {
    constructor() {
        super(...arguments);
        this.isOngoing = false;
        this.gifts = [];
        this.setting = null;
        this.items = [
            {
                cd: 0,
                img: '',
                canBuy: false,
                packageId: -1,
            },
            {
                cd: 0,
                img: '',
                canBuy: false,
                packageId: -1,
            },
            {
                cd: 0,
                img: '',
                canBuy: false,
                packageId: -1,
            },
            {
                cd: 0,
                img: '',
                canBuy: false,
                packageId: -1,
            },
        ];
        this.timer = -1;
        this.isValidateMobile = false;
        this.diamondService = new DiamondService();
    }
    beforeMount() {
        GetInfoByID(this.activityId).then((res) => {
            const current = new Date().getTime() / 1000;
            const startAt = res.getStartAt()?.getSeconds() || 0;
            const endAt = res.getEndAt()?.getSeconds() || 0;
            if (current > startAt && current < endAt) {
                this.isOngoing = true;
                this.timer = setInterval(() => {
                    this.updateItems();
                }, 1000);
            }
            this.gifts = res.getGiftList().map(o => {
                return {
                    itemId: o.getItemId(),
                    giftId: o.getGiftId(),
                    img: o.getItem()?.getImgUrl() || '',
                    name: '',
                    badge: 0,
                };
            });
            this.refresh();
        });
    }
    mounted() {
        fetch(`${process.env.VUE_APP_SETTING_HOST}/activity/${this.activityId}/setting.json`, { cache: 'no-store' }).then(res => {
            return res.json();
        }).then(setting => {
            this.setting = setting;
            this.$nextTick(() => {
                const el = this.$el;
                if (el) {
                    el.style.backgroundImage = `url('${process.env.VUE_APP_RESOURCE_HOST}/activity/${this.activityId}/btn_bg.png')`;
                }
            });
            this.refresh();
        });
        if (this.isAuthed) {
            this.checkIsValidateMobile();
        }
    }
    get canControl() {
        return this.isValidateMobile && this.isAuthed;
    }
    get imgHost() {
        return process.env.VUE_APP_RESOURCE_HOST;
    }
    itemStatus(index) {
        let canBuy = false;
        let isCounting = false;
        let text = '';
        let img = '';
        let ribbon = '';
        let border = '';
        let labelImg = '';
        const item = this.items[index];
        if (item) {
            if (typeof item.cd === 'number') {
                isCounting = item.cd > 0;
            }
            canBuy = item.canBuy;
            if (isCounting) {
                text = toHHMMSS(item.cd);
            }
            border = isCounting ? this.setting.border.disabled : this.setting.border.enabled;
            switch (index) {
                case 0:
                    img = isCounting ? this.setting.diamond.icon_disabled : this.setting.diamond.icon_enabled;
                    labelImg = isCounting ? '' : this.setting.diamond.label;
                    break;
                case 1:
                    img = isCounting ? this.setting.gift.icon_disabled : this.setting.gift.icon_enabled;
                    labelImg = isCounting ? '' : this.setting.gift.label;
                    break;
                case 2:
                    border = canBuy ? this.setting.border.enabled : this.setting.border.disabled;
                    img = canBuy ? this.setting.smallpack.icon_enabled : this.setting.smallpack.icon_disabled;
                    labelImg = canBuy ? this.setting.smallpack.label : this.setting.sold_out_img;
                    ribbon = isCounting ? this.setting.smallpack.ribbon_disabled : this.setting.smallpack.ribbon_enabled;
                    break;
                case 3:
                    border = canBuy ? this.setting.border.enabled : this.setting.border.disabled;
                    img = canBuy ? this.setting.regularpack.icon_enabled : this.setting.regularpack.icon_disabled;
                    labelImg = canBuy ? this.setting.regularpack.label : this.setting.sold_out_img;
                    ribbon = isCounting ? this.setting.regularpack.ribbon_disabled : this.setting.regularpack.ribbon_enabled;
                    break;
            }
        }
        return {
            canBuy,
            isCounting,
            text,
            img,
            ribbon,
            border,
            labelImg,
        };
    }
    loadItems() {
        if (!this.canControl) {
            return;
        }
        const item = this.items[0];
        if (item) {
            this.diamondService.CheckFreeDiamond({
                accessToken: this.accessToken
            }).then(cooldownSeconds => {
                item.cd = cooldownSeconds;
            });
        }
        this.diamondService.GetDiamondPackages({
            accessToken: this.accessToken,
        }).then((res) => {
            const [_, dailyItem, smallItem, bigItem] = this.items;
            const dailyDiamond = res.find(o => o.type === DPT_DAILY);
            if (dailyDiamond && dailyItem) {
                dailyItem.cd = dailyDiamond.canBuy ? -1 : secondsUntilEndOfDate();
                dailyItem.canBuy = dailyDiamond.canBuy;
                dailyItem.packageId = dailyDiamond.id;
            }
            const [smallMonth, bigMonth] = res.filter(o => o.type === DPT_MONTHLY);
            if (smallMonth && smallItem) {
                smallItem.canBuy = smallMonth.canBuy;
                smallItem.packageId = smallMonth.id;
            }
            if (bigMonth && bigItem) {
                bigItem.canBuy = bigMonth.canBuy;
                bigItem.packageId = bigMonth.id;
            }
        });
    }
    refresh() {
        if (!this.isOngoing) {
            return;
        }
        if (this.isAuthed) {
            this.checkIsValidateMobile();
        }
        this.loadItems();
    }
    updateItems() {
        this.items.forEach(item => {
            if (item && typeof item.cd === 'number') {
                if (item.cd >= 0) {
                    item.cd--;
                }
            }
        });
    }
    useItem(index) {
        if (!this.canControl) {
            this.checkIsValidateMobile();
            return;
        }
        if (!this.isOngoing) {
            return;
        }
        const item = this.items[index];
        const itemStatus = this.itemStatus(index);
        switch (index) {
            case 0:
                this.diamondService.CheckFreeDiamond({
                    accessToken: this.accessToken,
                    getItRightNow: true
                }).then(cooldownSeconds => {
                    if (item) {
                        item.cd = cooldownSeconds;
                    }
                });
                break;
            case 1:
            case 2:
            case 3:
                break;
        }
    }
    sendGiftByIndex(index) {
        const gift = this.gifts[index];
        if (gift) {
            this.sendGift(gift.giftId);
        }
    }
    sendGift(id) {
        if (!this.canControl) {
            this.checkIsValidateMobile();
            return;
        }
        if (!this.isOngoing) {
            return;
        }
        showDonation(this, this.modalId, id, () => {
            this.$emit('control', 'refresh');
        });
    }
    startLoginPanel() {
        this.$root.$emit('login');
    }
    beforeDestory() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = -1;
        }
    }
    async checkIsValidateMobile() {
        if (!this.isAuthed) {
            return this.startLoginPanel();
        }
        if (this.isValidateMobile) {
            return;
        }
        try {
            await this.diamondService.GetDiamondAmount({ accessToken: this.accessToken });
            this.isValidateMobile = true;
            this.refresh();
        }
        catch (error) {
            console.log('notvalidatemobile');
            this.$root.$emit('validate', () => {
                this.isValidateMobile = true;
                this.refresh();
            }, () => { }, this.accessToken);
        }
    }
};
__decorate([
    Throttle()
], GpgControlPanel.prototype, "useItem", null);
__decorate([
    Throttle()
], GpgControlPanel.prototype, "sendGift", null);
GpgControlPanel = __decorate([
    Component({
        name: 'GpgControlPanel',
        components: {
            ControlButton,
        },
    })
], GpgControlPanel);
export default GpgControlPanel;
