import { __decorate } from "tslib";
//@ts-nocheck
import { Vue, Component, Prop, } from 'vue-property-decorator';
import { GetLadyActivity } from '@/api/jkf-pay/activity';
let GiftList = class GiftList extends Vue {
    constructor() {
        super(...arguments);
        this.giftlist = [];
        this.icon = '';
        this.cardLeft = false;
    }
    beforeMount() {
        this.loadGifts();
    }
    loadGifts() {
        GetLadyActivity({
            activityId: this.activityId,
            ladyObjectId: this.ladyObjectId
        }).then(res => {
            this.giftlist = [res.getPart1(), res.getPart2(), res.getPart3(), res.getPart4()].map((o, index) => {
                return {
                    icon: `${process.env.VUE_APP_RESOURCE_HOST}/activity/${this.activityId}/gift${index}.png`,
                    count: o?.getAmount() || 0
                };
            });
            console.log(this.giftlist);
        });
    }
    mounted() {
        const post = this.$el.closest('.superbar');
        if (post) {
            if (post.closest('.view-box-card-theme'))
                this.cardLeft = true;
            const parent = post.closest('.md-card') || post.closest('.view-box-card-theme');
            if (parent) {
                const targetEl = parent.querySelector('.feed-message-box').querySelector('.giftlist');
                if (targetEl) {
                    targetEl.remove();
                }
                parent.querySelector('.feed-message-box')?.append(this.$el);
            }
        }
    }
};
__decorate([
    Prop()
], GiftList.prototype, "activityId", void 0);
__decorate([
    Prop()
], GiftList.prototype, "ladyObjectId", void 0);
GiftList = __decorate([
    Component({
        name: 'GiftList'
    })
], GiftList);
export default GiftList;
