import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Bag from '@/components/bag.vue';
import BagV2 from '@/components/bagV2.vue';
import VueJSModal from 'vue-js-modal';
Vue.use(VueJSModal);
import { ModalEvents } from '@/utils/enum';
let BagEntry = class BagEntry extends Vue {
    constructor() {
        super(...arguments);
        this.bagModalName = 'mybag';
        this.isMobile = (function () {
            var deviceWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
            return deviceWidth <= 480 ? true : false;
        })();
    }
    get targetBagVersion() {
        if (this.isV2) {
            return BagV2;
        }
        else {
            return Bag;
        }
    }
    mounted() {
        const that = this;
        this.$root.$on(ModalEvents.SHOW_BAG, function (componentId, donateSuccess, applySuccess) {
            if (that.isMobile && that.componentId === componentId) {
                that.showMobileBag(donateSuccess, applySuccess);
            }
            else if (that.componentId === componentId) {
                that.showDesktopBag(donateSuccess, applySuccess);
            }
        });
    }
    beforeDestroy() {
        this.$root.$off(ModalEvents.SHOW_BAG);
    }
    showDesktopBag(donateSuccess, applySuccess) {
        this.$modal.show(this.targetBagVersion, {
            name: this.bagModalName,
            close: this.closeBag,
            donateSuccess: donateSuccess,
            applySuccess: applySuccess,
            ladyObjectId: this.ladyObjectId,
            activityId: this.activityId,
            accessToken: this.accessToken,
        }, {
            height: 630,
            width: 922,
        });
    }
    showMobileBag(donateSuccess, applySuccess) {
        this.$modal.show(this.targetBagVersion, {
            name: this.bagModalName,
            close: this.closeBag,
            donateSuccess: donateSuccess,
            applySuccess: applySuccess,
            ladyObjectId: this.ladyObjectId,
            activityId: this.activityId,
            accessToken: this.accessToken,
        }, {
            height: '75%',
            width: '100%',
            classes: 'mobile-modal',
        });
    }
    closeBag() {
        this.$modal.hide(this.bagModalName);
    }
};
__decorate([
    Prop({ type: String, required: true })
], BagEntry.prototype, "componentId", void 0);
__decorate([
    Prop({ type: Number, required: true })
], BagEntry.prototype, "activityId", void 0);
__decorate([
    Prop({ type: String, required: true })
], BagEntry.prototype, "ladyObjectId", void 0);
__decorate([
    Prop()
], BagEntry.prototype, "accessToken", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], BagEntry.prototype, "isV2", void 0);
BagEntry = __decorate([
    Component({
        name: 'ActivityBagEntry',
    })
], BagEntry);
export default BagEntry;
