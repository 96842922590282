import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import DonationForm from '@/components/donation-form.vue';
import VueJSModal from 'vue-js-modal';
Vue.use(VueJSModal);
import { ModalEvents } from '@/utils/enum';
let DonationEntry = class DonationEntry extends Vue {
    constructor() {
        super(...arguments);
        this.saveName = 'undoneDonation';
        this.donationModalName = 'activityDonation';
        this.isMobile = (function () {
            var deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
            return deviceWidth <= 480 ? true : false;
        })();
    }
    mounted() {
        const that = this;
        this.$root.$on(ModalEvents.SHOW_DONATION, function (componentId, itemId, donateSuccess) {
            if (that.isMobile && that.componentId === componentId) {
                that.showMobileActivityDonation(itemId, donateSuccess);
            }
            else if (that.componentId === componentId) {
                that.showDesktopActivityDonation(itemId, donateSuccess);
            }
        });
    }
    beforeDestroy() {
        this.$root.$off(ModalEvents.SHOW_DONATION);
    }
    showDesktopActivityDonation(itemId, donateSuccess) {
        this.$modal.show(DonationForm, {
            name: this.donationModalName,
            close: this.closeActivityDonation,
            donateSuccess: donateSuccess,
            currentLadyObjectId: this.ladyObjectId,
            saveName: this.saveName,
            selectedGiftPackageId: itemId,
            accessToken: this.accessToken,
        }, {
            height: 'auto',
            width: 922
        });
    }
    showMobileActivityDonation(itemId, donateSuccess) {
        this.$modal.show(DonationForm, {
            name: this.donationModalName,
            close: this.closeActivityDonation,
            donateSuccess: donateSuccess,
            currentLadyObjectId: this.ladyObjectId,
            saveName: this.saveName,
            selectedGiftPackageId: itemId,
            accessToken: this.accessToken
        }, {
            height: 'auto',
            width: '100%',
            classes: 'mobile-modal'
        });
    }
    closeActivityDonation() {
        this.$modal.hide(this.donationModalName);
    }
};
__decorate([
    Prop({ type: String, required: true })
], DonationEntry.prototype, "componentId", void 0);
__decorate([
    Prop({ type: String, required: true })
], DonationEntry.prototype, "ladyObjectId", void 0);
__decorate([
    Prop()
], DonationEntry.prototype, "accessToken", void 0);
DonationEntry = __decorate([
    Component({
        name: 'ActivityDonationEntry',
    })
], DonationEntry);
export default DonationEntry;
