import { __decorate } from "tslib";
import { Vue, Prop, Watch } from 'vue-property-decorator';
export default class TemplateActivity extends Vue {
    get isAuthed() {
        return !!this.accessToken;
    }
    get modalId() {
        return `${this.componentId}${this.ladyObjectId}${this.activityId}`;
    }
    accessTokenChanged() {
        this.refresh();
    }
    activityIdChanged() {
        this.refresh();
    }
    ladyObjectIdChanged() {
        this.refresh();
    }
    refresh() {
        console.log('refresh');
    }
}
__decorate([
    Prop()
], TemplateActivity.prototype, "accessToken", void 0);
__decorate([
    Prop()
], TemplateActivity.prototype, "ladyObjectId", void 0);
__decorate([
    Prop()
], TemplateActivity.prototype, "activityId", void 0);
__decorate([
    Prop({ type: String, default: Date.now().toString() })
], TemplateActivity.prototype, "componentId", void 0);
__decorate([
    Watch('accessToken')
], TemplateActivity.prototype, "accessTokenChanged", null);
__decorate([
    Watch('activityId')
], TemplateActivity.prototype, "activityIdChanged", null);
__decorate([
    Watch('ladyObjectId')
], TemplateActivity.prototype, "ladyObjectIdChanged", null);
