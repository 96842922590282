import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueJSModal from 'vue-js-modal';
import { GetNotification } from '@/api/jkf-pay/activity';
Vue.use(VueJSModal);
let Notification = class Notification extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.unreadCount = 1;
        this.currentIndex = 1;
        this.currentUnreadNotification = {
            giftName: '',
            giftAmount: 0,
            giftAnimation: '',
            message: '',
            unreadCount: 0,
        };
    }
    mounted() {
        this.init();
    }
    async init() {
        this.isLoading = true;
        this.currentUnreadNotification = this.originUnreadNotification;
        this.unreadCount = this.originUnreadNotification.unreadCount;
        this.currentIndex = 1;
        this.isLoading = false;
    }
    async getMyUnreadNotification() {
        const that = this;
        const accessToken = this.accessToken;
        const unread = await GetNotification(accessToken).then((res) => {
            return {
                giftName: res.getTitle(),
                giftAmount: res.getAmount(),
                giftAnimation: res.getImgUrl(),
                message: res.getMessage(),
                unreadCount: res.getUnreadCount(),
            };
        });
        // const unread: UnreadNotification = {
        //   giftAmount: 1,
        //   giftAnimation: "https://develop.public.hare200.com/donate/gift/success_candyskin.png",
        //   giftName: "下一個",
        //   message: "請至 <b>贈送造型</b> 查看收到的造型！",
        //   unreadCount: 1
        // }
        return new Promise((resolve) => {
            resolve(unread);
        });
    }
    async getNextUnreadNotification() {
        const that = this;
        this.isLoading = true;
        if (this.currentIndex < this.unreadCount) {
            this.currentIndex += 1;
            this.currentUnreadNotification = await this.getMyUnreadNotification();
        }
        else {
            // @ts-ignore
            this.$modal.hide(this.$parent.name);
        }
        this.isLoading = false;
    }
    beforeOpen() {
        console.log('Opening...');
        let bodyDOM = document.querySelector('body');
        // @ts-ignore
        bodyDOM.classList.add('showing-modal');
    }
    beforeClose() {
        console.log('Closing...');
        let bodyDOM = document.querySelector('body');
        // @ts-ignore
        bodyDOM.classList.remove('showing-modal');
    }
};
__decorate([
    Prop({ type: Object, required: true })
], Notification.prototype, "originUnreadNotification", void 0);
__decorate([
    Prop()
], Notification.prototype, "accessToken", void 0);
Notification = __decorate([
    Component({
        name: 'ActivityNotification',
    })
], Notification);
export default Notification;
