import { __decorate } from "tslib";
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import ActivityV3Entry from '@/components/activityV3/activityV3-entry.vue';
import ActivityV2Entry from '@/components/gpg/activityV2-entry.vue';
import { AccessToken } from '@/api/pan/member';
import Noty from 'noty';
let PageActivity = class PageActivity extends Vue {
    constructor() {
        super(...arguments);
        this.authToken = null;
        this.accessToken = '';
        this.isGetAccessToken = false;
        this.ticker = 0;
        this.currentLadyUid = -1;
        this.isReady = false;
    }
    get activityId() {
        return parseInt(this.$route.params.id);
    }
    async notlogin() {
        parent.postMessage({ action: 'login', payload: null }, '*');
    }
    async notvalidatemobile() {
        parent.postMessage({ action: 'validate', payload: null }, '*');
    }
    handleMessageEvent(ev) {
        if (ev.data?.action === 'setAuthToken') {
            this.authToken = ev.data.payload;
            this.getAccessToken();
        }
        if (ev.data?.action === 'isChargeSuccess') {
            if (ev.data?.payload === true) {
                setTimeout(() => {
                    new Noty({ text: '鑽石儲值成功' }).show();
                }, 1000);
            }
            if (ev.data?.payload === false) {
                setTimeout(() => {
                    new Noty({ text: '鑽石儲值失敗' }).show();
                }, 1000);
            }
        }
        if (ev.data?.source === 'txg-f2e-jkface') {
            sessionStorage.setItem('ga4Params', JSON.stringify(ev.data.payload));
        }
    }
    mounted() {
        this.init();
    }
    beforeDestroy() {
        clearInterval(this.ticker);
        this.ticker = 0;
        window.removeEventListener('message', this.handleMessageEvent);
    }
    async init() {
        this.isReady = false;
        if (typeof this.$route.query.uid === 'string') {
            this.currentLadyUid = parseInt(this.$route.query.uid);
        }
        clearInterval(this.ticker);
        this.ticker = setInterval(this.getAccessToken, 5 * 60 * 1000);
        window.removeEventListener('message', this.handleMessageEvent);
        window.addEventListener('message', this.handleMessageEvent, false);
        window.parent.postMessage({ action: 'isMounted', payload: null }, '*');
        this.isReady = true;
    }
    async getAccessToken() {
        if (this.authToken) {
            try {
                this.accessToken = await AccessToken(this.authToken).then((res) => res.getAccessToken());
            }
            catch {
                // noop
            }
            this.isGetAccessToken = true;
        }
    }
    authTokenChanged() {
        if (this.outsideAuthToken === null)
            return window.location.reload();
        this.authToken = this.outsideAuthToken;
        this.getAccessToken();
    }
};
__decorate([
    Prop()
], PageActivity.prototype, "outsideAuthToken", void 0);
__decorate([
    Watch('outsideAuthToken')
], PageActivity.prototype, "authTokenChanged", null);
PageActivity = __decorate([
    Component({
        components: {
            ActivityV3Entry,
            ActivityV2Entry,
        },
    })
], PageActivity);
export default PageActivity;
