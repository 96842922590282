import { __decorate } from "tslib";
import { ReadObjectByObjectIds } from '@/api/pan/member';
import { GetTopFansOfLady } from '@/api/jkf-pay/activity';
import { Vue, Component, Prop } from 'vue-property-decorator';
let TopRankV3 = class TopRankV3 extends Vue {
    constructor() {
        super(...arguments);
        this.maxAmount = 10;
        this.isLoading = true;
        this.users = [];
    }
    get sortedUsers() {
        return this.users.sort((a, b) => a.order - b.order);
    }
    mounted() {
        this.init();
    }
    async init() {
        this.isLoading = true;
        this.users = [];
        this.users = await this.getTopFansOfLady();
        this.isLoading = false;
    }
    async getTopFansOfLady() {
        const fans = await GetTopFansOfLady(this.ladyObjectId, this.activityId).then((res) => {
            return res.getFansList().map((fan, order) => {
                return {
                    objectId: fan.getObjectId(),
                    name: '',
                    avatar: '',
                    order: order,
                };
            });
        });
        if (fans.length > 0) {
            await ReadObjectByObjectIds(fans.map((e) => e.objectId)).then((res) => {
                var users = res.map((user) => {
                    var targetFan = fans.find((fan) => fan.objectId === user.getObjectId()) || {
                        objectId: '',
                        name: '',
                        avatar: '',
                        order: 0,
                    };
                    targetFan.name = user.getDisplayName() || user.getUsername();
                    targetFan.avatar = user.getAvatar();
                });
            });
        }
        return new Promise((resolve) => {
            resolve(fans);
        });
    }
    close() {
        this.$emit('close');
    }
};
__decorate([
    Prop({ type: String, required: true })
], TopRankV3.prototype, "ladyObjectId", void 0);
__decorate([
    Prop({ type: Number, required: true })
], TopRankV3.prototype, "activityId", void 0);
TopRankV3 = __decorate([
    Component({
        name: 'ActivityTopRankV3',
    })
], TopRankV3);
export default TopRankV3;
