// import Vue from 'vue/types/umd'
import { ModalEvents } from '@/utils/enum';
function showBag(vm, componentId, donateCallback, applyCallback) {
    vm.$root.$emit(ModalEvents.SHOW_BAG, componentId, donateCallback, applyCallback);
}
function showTopRank(vm, componentId) {
    vm.$root.$emit(ModalEvents.SHOW_TOP_RANK, componentId);
}
function showDailyPackage(vm, componentId, packageId, purchaseCallback) {
    vm.$root.$emit(ModalEvents.SHOW_DAILY_PACKAGE, componentId, packageId, purchaseCallback);
}
function showDonation(vm, componentId, giftPackageId, donateCallback) {
    vm.$root.$emit(ModalEvents.SHOW_DONATION, componentId, giftPackageId, donateCallback);
}
export { showBag, showTopRank, showDailyPackage, showDonation };
